import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppService } from './services/app.service';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  usernameAttributes = 'email';
  signedIn = false;
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Courses',
      url: '/courses',
      icon: 'list'
    },
    {
      title: 'Trainees',
      url: '/trainees',
      icon: 'list'
    },
    {
      title: 'Registrations',
      url: '/registrations',
      icon: 'list'
    },
    {
      title: 'Admin',
      url: '/admin',
      icon: 'list'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public appService: AppService
  ) {
    this.initializeApp();
    appService.initApp();
    appService.$app.subscribe(app => {
      // console.log("App",app[0])
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  async clickedOnSignOut() {
    await Auth.signOut().then(res => {
      this.signedIn = false;
      window.location.assign('https://app.viarspace.net')
    });
  }
}
