import { Component, OnInit } from '@angular/core';
import { Apollo } from "apollo-angular";
import {ModalController } from "@ionic/angular";
import gql from "graphql-tag";
import {AppService} from '../../services/app.service'
@Component({
  selector: 'app-newaccountuser',
  templateUrl: './newaccountuser.page.html',
  styleUrls: ['./newaccountuser.page.scss'],
})
export class NewaccountuserPage implements OnInit {
  step=1
  checkingemail=false
  notfoundmessage
  fullname
  newuserform={
    role:'',
    email:'',
    userAccountsUsersId: "",
    accountid:this.appService.getUserAccount().db['account'].id,
    userAccountsAccountId: this.appService.getUserAccount().db['account'].id,
    userid:"",
  }
  roles
  constructor(
    private apollo:Apollo,
    private appService: AppService,
    private modalCtrl: ModalController
  ) { 
    
  }

  ngOnInit() {
    
  }
  
  getRoles(){
    this.apollo
    .query({
      query: gql`
        query enumValuesOfMetaInformationTags {
          __type(name: "UserAccountRole") {
            name
            enumValues {
              name
            }
          }
        }
      `
    }).subscribe(({data}) => {
      //console.log("data", data['__type'].enumValues)
      this.roles=data['__type'].enumValues
      }
    )
  }
  
  setStep(direction){
    switch(direction){
      case "forward":
        this.step ++
        break;
      case "back":
        this.step --
        break
    }
  }
  
  checkEmail(){
    this.checkingemail=true;
    let self=this
    this.apollo
    .query({
      variables:{
        email:this.newuserform.email
      },
      query: gql`
        query getUserFromEmail($email:String) {
          __typename
          listUsers(filter: {email: {eq: $email}}) {
            items {
              first_name
              last_name
              id
            }
          }
        }
      `
    }).subscribe(({data}) => {
        self.checkingemail=false;
        console.log(data)
        if (data['listUsers'].items.length > 0){
          self.fullname=data['listUsers'].items[0].first_name + " " + data['listUsers'].items[0].last_name
          self.newuserform.userAccountsUsersId=data['listUsers'].items[0].id
          self.newuserform.accountid=data['listUsers'].items[0].id
          self.newuserform.userid=data['listUsers'].items[0].id
          self.getRoles()
          self.setStep("forward")
        }else{
          self.notfoundmessage="Email " + self.newuserform.email + " was not found. Please try again." 
          console.log("Not Found")
        }
      }
    )
    
    

  }
  
  closeModal() {
    console.log("Closing modal")
    this.modalCtrl.dismiss();
  }
  
  addUser(){
    console.log("this.newuserform", this.newuserform)
    this.apollo
    .mutate({
      variables:this.newuserform,
      mutation: gql`
        mutation setUserForAccount(
          $accountid:ID!,
          $userAccountsUsersId: ID,
          $userAccountsAccountId: ID,
          $role:UserAccountRole,
          $creator:ID,
          $userid:ID,
        ) {
          __typename
          createUserAccounts(input: {
            accountid: $accountid, 
            userAccountsUsersId: $userAccountsUsersId, 
            userAccountsAccountId: $userAccountsAccountId, 
            status: ACTIVE, 
            role: $role, 
            creator: $creator, 
            userid: $userid
          }) {
            id
          }
        }

      `
    }).subscribe(({data}) => {
     // console.log("data", data['__type'].enumValues)
      //this.roles=data['__type'].enumValues
      this.closeModal()
      }
    )
  }

}
