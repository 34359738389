import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from "@ionic/angular";
import gql from 'graphql-tag';
import { Apollo } from "apollo-angular";
import {AppService} from "../../services/app.service"
import * as uuid from "uuid";

@Component({
  selector: 'app-new',
  templateUrl: './new.page.html',
  styleUrls: ['./new.page.scss'],
})

export class NewPage implements OnInit {
deviceTypes
newdevice={
  name:"",
  type:"",
  model:"",
  linkcode:""
}
  constructor(
    private modalCtrl: ModalController,
    private apollo: Apollo,
    private appService:AppService,
    ) { }

  ngOnInit() {
    //this.generateLinkCode()
  }
  
  generateLinkCode(){
    for (var i=0; i<5; i++){
      this.newdevice.linkcode = this.newdevice.linkcode + String.fromCharCode(64 + Math.floor(Math.random() * 5) + 1)
    }
  }
  
  ionViewWillEnter(){
    this.getDeviceTypes()
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }
  
  getDeviceTypes(){
    this.apollo.query({
      query:getDeviceTypesQuery
    }).subscribe(({data}) => {
      this.deviceTypes=data['__type'].enumValues
    })
  }
  
  setNewDevice(){
    this.generateLinkCode()
    this.apollo.mutate({
        mutation: setNewDeviceMutation,
        variables:this.appService.sanititizeForDynamo({
          id:uuid.v4(),
          accountid:this.appService.getUserAccount().db['account'].id,
          deviceaccountid:this.appService.getUserAccount().db['account'].id,
          linkcode:this.newdevice.linkcode,
          name:this.newdevice.name,
          type:this.newdevice.type,
          model:this.newdevice.model,
          status:"CREATED",
          linkcodetimestamp:Date.now(),
          active:false
        })
      })
      .subscribe(({data}) => {
        this.apollo.subscribe({
          query:gql`
          subscription subscribeToLinkCodes {
            __typename
            onUpdateDevice {
              id
            }
          }
          `
        }).subscribe(({data}) => {
          console.log("Subscription changed", data['onUpdateDevice'].id)
          let theDeviceDetailQuery=gql`
            query getDeviceQuery($id:ID!) {
              __typename
              getDevice(id: $id) {
                active
                deviceid
                id
                lastip
                linkcode
                linked
                linkheaders
                linktimestamp
                model
                name
                status
                type
              }
            }
          `
          let theDeviceDetailVariables={
            id:data['onUpdateDevice'].id
          }
          this.apollo.query({
            query:theDeviceDetailQuery,
            variables:theDeviceDetailVariables
          }).subscribe(({data}) => {
            console.log("Device data", data)
            this.closeModal()
          })
        })
        // this.closeModal()
      })
  }
}

const setNewDeviceMutation=gql`
mutation setNewDevice(
  $id:ID!
  $accountid:ID!
  $deviceaccountid:ID
  $linkcode:String
  $model:String
  $name:String
  $type:DeviceType
  $status:DeviceStatus
  $linkcodetimestamp:AWSTimestamp
  $active:Boolean
  ) {
  __typename
  createDevice(input: {
    id: $id
    accountid: $accountid
    deviceAccountId: $deviceaccountid
    linkcode: $linkcode
    model: $model
    name: $name
    type: $type
    status: $status
    linkcodetimestamp: $linkcodetimestamp
    active: $active
  }) {
    id
  }
}
`

const getDeviceTypesQuery=gql`
query MyQuery {
  __type(name:"DeviceType") {
    name
    enumValues{
      name
    }
  }
}
`