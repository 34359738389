/** @format */

import { Component, OnInit } from "@angular/core";
import { MediaService } from "../../../../services/media.service";
import { IonProgressBar } from "@ionic/angular";
@Component({
	selector: "app-video-loading-modal",
	templateUrl: "./video-loading-modal.page.html",
	styleUrls: ["./video-loading-modal.page.scss"],
})
export class VideoLoadingModalPage implements OnInit {
	theMediaService;
	constructor(public mediaService: MediaService) {}

	ngOnInit() {}
}
