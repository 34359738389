/** @format */

import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Subscription } from "apollo-angular";
import gql from "graphql-tag";
import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";
import * as _ from "lodash";
import * as uuid from "uuid";
import { AppService } from "../services/app.service";
@Injectable({
	providedIn: "root",
})
export class TraineesService {
	activeTrainee;
	studentsObservable;
	$students: any[] = [];
	student: any;
	newTraineeAccountVariables: any;
	constructor(private apollo: Apollo, private appservice: AppService) {}

	async createTrainee(newTrainee) {
		const TraineeAccountId = uuid.v4();
		const newTraineeQuery = await this.apollo
			.mutate({
				variables: this.appservice.sanititizeForDynamo(newTrainee),
				mutation: gql(this.newTraineeMutation),
			})
			.subscribe();

		this.newTraineeAccountVariables = {
			id: uuid.v4(),
			studentAccountAccountId: newTrainee.accountid,
			studentAccountStudentId: newTrainee.id,
			createdby: newTrainee.studentUserId,
			createdon: Date.now(),
			status: "ACTIVE",
		};

		const newTraineeAccount = await this.apollo
			.mutate({
				variables: this.appservice.sanititizeForDynamo(
					this.newTraineeAccountVariables
				),
				mutation: gql(this.newTraineeAccountMutation),
			})
			.subscribe();

		return true;
		/*
    let promise = new Promise((resolve, reject) => {
    
   
    return this.apollo
      .mutate({
        variables: this.appservice.sanititizeForDynamo(newTrainee),
        mutation: gql(this.newTraineeMutation)
      })
      .subscribe((newtrainee) => {
        resolve(newtrainee);
      });
    });
    return promise;*/
	}

	setActiveTrainee(id) {
		this.activeTrainee = id;
	}

	getActiveTrainee() {
		return this.activeTrainee;
	}

	getStudents() {
		//console.log("Getting students")
		/*let getStudentsQuery0=`
    query listStudents($accountid:ID!) {
        getAccount(id: $accountid) {
          students {
            items {
              companyid
              gender
              givenname
              id
              language
              middlename
              surname
              photo
            }
          }
          id
        }
      }

    `*/
		let getStudentsQuery = `
    query getStudentsQuery ($accountid:ID!){
      getAccount(id: $accountid) {
        students {
          items {
            student {
              companyid
              givenname
              id
              middlename
              photo
              surname
              language
              dialect
              pollyvoice
            }
          }
        }
      }
    }
    `;
		let getStudentsQueryVariables = {
			accountid: this.appservice.getUserAccount().db["account"].id,
		};
		//console.log("query",getStudentsQuery, getStudentsQueryVariables)
		return this.apollo
			.watchQuery({
				query: gql(getStudentsQuery),
				fetchPolicy: "no-cache",
				variables: getStudentsQueryVariables,
			})
			.valueChanges.subscribe((students) => {
				//console.log("Students", students.data['getAccount'].students.items)
				let studentsArray = _.orderBy(
					students.data["getAccount"].students.items,
					[(studentItem) => studentItem.student.surname.toLowerCase()],
					["asc"]
				);
				// this.$courses = Object.assign([], coursesArray);
				this.$students = null;
				this.$students = studentsArray;
				//console.log("getting Students", this.$students);
			});
	}

	getDialects(language) {
		for (let lang in this.appservice.config.languages) {
			if (this.appservice.config.languages[lang].language === language) {
				return this.appservice.config.languages[lang].dialects;
			}
		}
	}

	getPollyVoice(language, dialect) {
		for (let lang in this.appservice.config.languages) {
			if (this.appservice.config.languages[lang].language === language) {
				for (let languagedialect in this.appservice.config.languages[lang]
					.dialects) {
					if (
						this.appservice.config.languages[lang].dialects[languagedialect]
							.dialect === dialect
					) {
						for (let voice in this.appservice.config.languages[lang].dialects[
							languagedialect
						].voices) {
							if (
								this.appservice.config.languages[lang].dialects[languagedialect]
									.voices[voice].default === true
							) {
								return this.appservice.config.languages[lang].dialects[
									languagedialect
								].voices[voice].voice;
							} else {
							}
						}
					} else {
					}
				}
			} else {
			}
		}
	}

	getStudent(id) {
		this.apollo
			.watchQuery({
				query: gql`
					query getStudent($id: ID!) {
						__typename
						getStudent(id: $id) {
							companyid
							dialect
							gender
							givenname
							govtissuedid
							id
							idrekognition
							language
							middlename
							photo
							photorekognition
							surname
						}
					}
				`,
				variables: {
					id: id,
				},
			})
			.valueChanges.subscribe((student) => {
				this.student = student.data["getStudent"];
			});
	}

	newTraineeAccountMutation = `
  mutation MyMutation (
    $id:ID!
    $studentAccountAccountId:ID
    $studentAccountStudentId:ID
    $createdby:ID
    $createdon:AWSTimestamp
  ){  __typename
    createStudentAccount(input: {
      id: $id,
      studentAccountAccountId: $studentAccountAccountId, 
      studentAccountStudentId: $studentAccountStudentId,
      createdby: $createdby, 
      createdon: $createdon, 
      status: ACTIVE
  }) {
      id
    }
  }

  `;

	newTraineeMutation = `
          mutation createStudent(
            $id: ID!
            $accountid:ID!
            $companyid: String
            $studentUserId:ID
            $govtidcountry:String
            $govtidtype:String
            $govtissuedid:String
            $givenname: String
            $middlename: String
            $surname: String
            $gender: String
            $language: String
            $dialect:String
            $email:String
            $cellphone:String
            $addressline1:String
            $addressline2:String
            $addresscity:String
            $addressstate:String
            $addresspostalcode:String
            $addresscountry:String
            $socialweb:String
            $socialfacebook:String
            $socialinstagram:String
            $socialtelegram:String
            $socialtwitter:String
            $socialyoutube:String
            $socialskype:String
            $sociallinkedin:String
            $socialpinterest:String
            $socialdropbox:String
            $photo: String
            $pollyvoice:String
          ) {
            __typename
            createStudent(
              input: {
            id: $id
            accountid:$accountid
            companyid: $companyid
            studentUserId:$studentUserId
            govtidcountry:$govtidcountry
            govtidtype:$govtidtype
            govtissuedid:$govtissuedid
            givenname: $givenname
            middlename: $middlename
            surname: $surname
            gender: $gender
            language: $language
            dialect:$dialect
            email:$email
            cellphone:$cellphone
            addressline1:$addressline1
            addressline2:$addressline2
            addresscity:$addresscity
            addressstate:$addressstate
            addresspostalcode:$addresspostalcode
            addresscountry:$addresscountry
            socialweb:$socialweb
            socialfacebook:$socialfacebook
            socialinstagram:$socialinstagram
            socialtelegram:$socialtelegram
            socialtwitter:$socialtwitter
            socialyoutube:$socialyoutube
            socialskype:$socialskype
            sociallinkedin:$sociallinkedin
            socialpinterest:$socialpinterest
            socialdropbox:$socialdropbox
            photo: $photo
            pollyvoice:$pollyvoice
              }
            )
            {
              id
            }            
          }
        `;
}
