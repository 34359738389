/** @format */

import { Injectable } from "@angular/core";
import { Storage } from "aws-amplify";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import * as uuid from "uuid";
import API from "@aws-amplify/api";
import { AppService } from "src/app/services/app.service";
import { CoursesService } from "src/app/services/courses.service";
@Injectable({
	providedIn: "root",
})
export class MediaService {
	uploadProgress;
	videoUploadMessage;
	uploadProgressPercent;
	uploadProgressMessage = "Processing...";
	baseMetadata = {
		generator: "Viarspace",
	};
	constructor(
		private coursesService: CoursesService,
		private appService: AppService,
		private apollo: Apollo
	) {}

	getPathToThumbnail(thumbnail, size) {
		return (
			this.appService.config.paths.contentdomain +
			"public/" +
			this.appService.config.paths.files.mediadownloads +
			thumbnail +
			"-" +
			size +
			".jpg"
		);
	}

	resizeImageAndSave(file, type, functionMetadata) {
		return new Promise((resolve, reject) => {
			let self = this;
			let direction;
			let sizes;
			let filepath;
			let filename = uuid.v4();
			let fileextension;
			let contenttype;
			let contextMetadata = {
				filename: filename,
			};
			let imagenum = 1;
			switch (type) {
				case "croppedimage":
					direction = "mediadownloads";
					sizes = [64, 128, 256, 512];
					fileextension = ".jpg";
					contenttype = "image/jpeg";
					filepath = this.appService.getPathToFile(direction) + filename;
					for (var size = 0; size < sizes.length; size++) {
						imagenum++;
						this.resizeImage(file, sizes[size]).then((resizedImage: object) => {
							let imageMetadata = {};
							this.uploadFileWithParameters({
								filelocation: filepath + "-" + sizes[size] + fileextension,
								image: resizedImage,
								meta: {
									...this.baseMetadata,
									...imageMetadata,
									...functionMetadata,
								},
								contenttype,
							}).then((res) => {
								if (imagenum === sizes.length) {
									resolve(true);
								}
							});
						});
					}
					break;
				case "originalimage":
					direction = "mediadownloads";
					break;
			}
		});
	}

	uploadFileWithParameters(...params) {
		return new Promise((resolve, reject) => {
			let args = params[0];

			Storage.put(args.filelocation, args.image, {
				metadata: args.metadata,
				contentType: args.contentType,
				progressCallback(progress) {},
			}).then((result) => {
				resolve(result);
			});
		});
	}

	uploadFile(upload) {
		let promise = new Promise((resolve, reject) => {
			upload.filepath = this.appService.getPathToFile(upload.direction);
			let self = this;
			let linkid;
			let linkidvalue;
			let thefileLocation = upload.filepath + upload.filename;
			switch (upload.type) {
				case "USERIMAGE":
					linkid = "mediaUserId";
					linkidvalue = upload.linkidvalue;
					break;
				case "ACCOUNTIMAGE":
					linkid = "mediaAccountId";
					linkidvalue = upload.linkidvalue;
					break;
				case "COURSEAUDIO":
					linkid = "mediaCourseId";
					linkidvalue = upload.linkidvalue;
					break;
				case "COURSEVIDEO":
					linkid = "mediaCourseId";
					linkidvalue = upload.linkidvalue;
					break;
				case "COURSEIMAGE":
					linkid = "mediaCourseId";
					linkidvalue = upload.linkidvalue;
					break;
				case "COURSEAUDIO":
					linkid = "mediaCourseId";
					linkidvalue = upload.linkidvalue;
					break;
				default:
					upload.type = "STUDENTPHOTO";
			}
			Storage.put(thefileLocation, upload.image, {
				metadata: { ...this.baseMetadata, ...upload.meta },
				contentType: upload.contentType,
				progressCallback(progress) {
					self.uploadProgress = progress;
				},
			}).then((result) => {
				let variables = {
					id: uuid.v4(),
					accountid: this.appService.getUserAccount().db["account"].id,
					[linkid]: [linkidvalue],
					type: upload.type,
					status: "ACTIVE",
					uploadedon: Date.now(),
					originalfilename: upload.meta.name,
					converter: upload.converter,
					course: upload.course,
					sizeKb: upload.meta.size,
					width: upload.width,
					height: upload.height,
					duration: upload.duration,
					meta: JSON.stringify(
						this.appService.sanititizeForDynamo({
							...this.baseMetadata,
							...upload.metadata,
						})
					),
					mimetype: upload.contentType,
					lastmodified: parseInt(upload.meta.lastModified),
				};
				let mutation = `
            mutation CreateMedia(
              $id: ID!, 
              $accountid: ID, 
              $mediaCourseId: ID, 
              $type: MediaType,
              $status: Status,
              $uploadedon: AWSTimestamp, 
              $originalfilename: String,
              $converter: AWSJSON, 
              $sizeKb: Int, 
              $width: Int,
              $height: Int,
              $duration: Int, 
              $meta: AWSJSON,
              $mimetype: String,
              $lastmodified: AWSTimestamp
            ) {
            __typename
            createMedia(input: {
              id: $id,
              accountid: $accountid, 
              mediaCourseId: $mediaCourseId, 
              type: $type,
              status: $status,
              uploadedon: $uploadedon,
              originalfilename: $originalfilename,
              converter: $converter, 
              sizeKb: $sizeKb,
              width: $width,
              height: $height,
              duration: $duration, 
              meta: $meta,
              mimetype: $mimetype, 
              lastmodified: $lastmodified
            }){
              id
            }
          }
        `;
				this.apollo
					.mutate({
						variables: variables,
						mutation: gql(mutation),
					})
					.subscribe((result) => {
						resolve(result);
					});
			});
		});
		return promise;
	}

	setRekognitionData(MediaId, rekognitionIndexFace, rekognitionDetectFace) {
		let promise = new Promise((resolve, reject) => {
			let variables = {
				id: MediaId,
				rekognitionIndexFace: JSON.stringify(
					this.appService.sanititizeForDynamo(rekognitionIndexFace)
				),
				rekognitionDetectFace: JSON.stringify(
					this.appService.sanititizeForDynamo(rekognitionDetectFace)
				),
			};
			let updateMediaGql = `mutation updateMedia(
          $id:ID!, 
          $rekognitionDetectFace:AWSJSON, 
          $rekognitionIndexFace:AWSJSON
        ) {
        __typename
        updateMedia(
          input: {
            id: $id, 
            rekognitionDetectFace: $rekognitionDetectFace, 
            rekognitionIndexFace: $rekognitionIndexFace
          }) 
        {
          id
        }
      }`;

			this.apollo
				.mutate({
					variables: variables,
					mutation: gql(updateMediaGql),
				})
				.subscribe((result) => {
					resolve(result);
				});
		});
		return promise;
	}

	resizeImage(image, height) {
		let promise = new Promise((resolve, reject) => {
			const img = new Image();
			// @ts-ignore
			img.src = image;
			img.onload = () => {
				const elem = document.createElement("canvas");
				elem.height = height;
				const scaleFactor = elem.height / img.height;
				elem.width = img.width * scaleFactor;
				const ctx = elem.getContext("2d");
				ctx.drawImage(img, 0, 0, img.width * scaleFactor, elem.height);
				ctx.canvas.toBlob(
					(blob) => {
						resolve(blob);
					},
					"image/jpeg",
					0.8
				);
			};
		});
		return promise;
	}
	convertVideoFormats() {
		console.log(
			"this.appService.config.compression",
			this.appService.config.compression
		);
		let promise = new Promise((resolve, reject) => {
			API.post("viar", "/convertVideoFormats", {
				body: {
					compressions: this.appService.config.compression.video,
					inputfile: uuid.v4(),

					outputfile: uuid.v4(),
				},
			}).then((res) => {
				console.log(res);
			});
		});
		return promise;
	}

	cutVideo(params) {
		let outputFile = uuid.v4();
		let promise = new Promise((resolve, reject) => {
			API.post("viar", "/convertVideoFormats", {
				body: {
					compressions: this.appService.config.compression.video,
					inputfile: params.original,
					inputprefix: params.inputprefix,
					outputfile: outputFile,
					in: String((Math.round(params.in * 100) / 100).toFixed(3)),
					duration: String(
						(Math.round(params.duration * 100) / 100).toFixed(3)
					),
				},
			}).then((res) => {
				console.log("Cut result", res);
				resolve(outputFile);
			});
		});
		return promise;
	}

	/** Convert seconds to SMPTE timecode JSON object, example input is html video.currentTime */
	secondsToSMPTE(seconds, framerate) {
		var f = Math.floor((seconds % 1) * framerate);
		var s = Math.floor(seconds);
		var m = Math.floor(s / 60);
		var h = Math.floor(m / 60);
		m = m % 60;
		s = s % 60;

		return { h: h, m: m, s: s, f: f };
	}

	/** Pretty print SMPTE timecode JSON object */
	SMPTEToString(timecode) {
		if (timecode.h < 10) {
			timecode.h = "0" + timecode.h;
		}
		if (timecode.m < 10) {
			timecode.m = "0" + timecode.m;
		}
		if (timecode.s < 10) {
			timecode.s = "0" + timecode.s;
		}
		if (timecode.f < 10) {
			timecode.f = "0" + timecode.f;
		}

		return timecode.h + ":" + timecode.m + ":" + timecode.s + ":" + timecode.f;
	}

	generate;
	/*
	processVideo2(videoFile, type) {
		const thisself = this;
		this.uploadProgressMessage = "Converting";
		this.uploadProgressPercent = 0;
		let promise = new Promise((resolve, reject) => {
			let createMediaVariables = {
				id: uuid.v4(),
				extension: "." + videoFile.name.split(".")[1].toLowerCase(),
				mediaCourseId: this.coursesService.getCourseId(),
				originalfilename: videoFile.name,
				sizeKb: videoFile.size,
				status: "ACTIVE",
				type: type,
				uploadedon: Date.now(),
			};
			this.processVideoMediaTable(createMediaVariables);
			this.processVideoUploadFile(
				videoFile,
				this.coursesService.getPathToMediaUploads(
					createMediaVariables.id,
					createMediaVariables.extension
				)
			).then((res) => {
				this.uploadProgressMessage = "Converting";
				this.processVideoConvertFormats(
					createMediaVariables.id,
					createMediaVariables.extension
				).then((res) => {
					this.uploadProgressMessage = "Generating Versions";
					this.processVideoStatus(
						createMediaVariables.id,
						"ACTIVE",
						JSON.stringify(this.appService.sanititizeForDynamo(res))
					);
					resolve(createMediaVariables.id);
				});
			});
		});
		return promise;
	}

	processVideo(videoFile, type) {
		const thisself = this;
		this.uploadProgressMessage = "Converting";
		this.uploadProgressPercent = 0;
		let promise = new Promise((resolve, reject) => {
			let createMediaVariables = {
				id: uuid.v4(),
				extension: "." + videoFile.name.split(".")[1].toLowerCase(),
				mediaCourseId: this.coursesService.getCourseId(),
				originalfilename: videoFile.name,
				sizeKb: videoFile.size,
				status: "ACTIVE",
				type: type,
				uploadedon: Date.now(),
			};
			this.processVideoMediaTable(createMediaVariables);
			this.processVideoUploadFile(
				videoFile,
				this.coursesService.getPathToMediaUploads(
					createMediaVariables.id,
					createMediaVariables.extension
				)
			).then((res) => {
				this.uploadProgressMessage = "Converting";
				this.processVideoConvertFormats(
					createMediaVariables.id,
					createMediaVariables.extension
				).then((res) => {
					this.uploadProgressMessage = "Generating Versions";
					this.processVideoStatus(
						createMediaVariables.id,
						"ACTIVE",
						JSON.stringify(this.appService.sanititizeForDynamo(res))
					);
					resolve(createMediaVariables.id);
				});
			});
		});
		return promise;
	}*/

	processVideoMediaTable(createMediaVariables) {
		let promise = new Promise((resolve, reject) => {
			this.apollo
				.mutate({
					variables: createMediaVariables,
					mutation: gql(CreateMediaGql),
				})
				.subscribe((res) => {
					resolve(res);
				});
		});
		return promise;
	}

	processVideoConvertFormats(fileId, extension, inputprefix) {
		let promise = new Promise((resolve, reject) => {
			API.post("viar", "/convertVideoFormats", {
				body: {
					compressions: this.appService.config.compression.video,
					inputfile: fileId + extension,
					inputprefix: inputprefix,
					outputfile: fileId,
					inputclippings: {},
				},
			}).then((res) => {
				resolve(res);
			});
		});
		return promise;
	}

	processVideoUploadFile(file, filename) {
		let self = this;

		let promise = new Promise((resolve, reject) => {
			Storage.put(filename, file, {
				progressCallback(progress) {
					const value = Math.round((progress.loaded / progress.total) * 100);
					self.uploadProgressPercent = value / 100;
					self.uploadProgressMessage = "Uploading ";
				},
				contentType: file.type,
			}).then((res) => {
				self.uploadProgressPercent = 0;
				resolve(res);
			});
		});
		return promise;
	}

	processVideoStatus(id, status, converter) {
		let promise = new Promise((resolve, reject) => {
			this.apollo
				.mutate({
					variables: {
						id: id,
						status: status,
						converter: converter,
					},
					mutation: gql(UpdateMediaStatusGql),
				})
				.subscribe((res) => {
					resolve(res);
				});
		});
		return promise;
	}

	getFileExtension(file) {
		return file.split(".")[1];
	}

	checkForFile(file) {
		console.log("Checking for file", file);
		var xhr = new XMLHttpRequest();

		let promise = new Promise((resolve, reject) => {
			let poll = setInterval(() => {
				try {
					xhr.open("HEAD", file, false);
					xhr.send();

					if (xhr.status === 200) {
						resolve(true);
						clearInterval(poll);
					} else {
						console.log("Retry");
					}
				} catch {
					console.log("Retry");
				}
			}, 3000);
		});
		return promise;
	}
}
const UpdateMediaStatusGql = `
mutation UpdateMediaStatus(
    $id: ID!
    $status: Status
    $converter: AWSJSON
  ) {
    __typename
    updateMedia(
      input: {
        id: $id
        status: $status
        converter: $converter
      }
    ) {
      id
    }
  }
`;

const CreateMediaGql = `
  mutation CreateMedia(
    $id: ID!
    $mediaCourseId: ID
    $originalfilename: String
    $sizeKb: Int
    $status: Status
    $type: MediaType
    $uploadedon: AWSTimestamp
  ) {
    __typename
    createMedia(
      input: {
        id: $id
        mediaCourseId: $mediaCourseId
        originalfilename: $originalfilename
        sizeKb: $sizeKb
        status: $status
        type: $type
        uploadedon: $uploadedon
      }
    ) {
      id
    }
  }
`;
