import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild
} from "@angular/core";
import { LoadingController, ModalController } from "@ionic/angular";
import { Storage } from "aws-amplify";
import { TraineesService } from "../../services/trainees.service";
import { AppService } from "../../services/app.service";
import {MediaService} from '../../services/media.service';
import * as uuid from "uuid";
import { ImageCroppedEvent } from 'ngx-image-cropper';
import * as countryList from '../../countries/countries.json';


@Component({
  selector: "app-trainee-new",
  templateUrl: "./trainee-new.page.html",
  styleUrls: ["./trainee-new.page.scss"]
})
export class TraineeNewPage implements OnInit {
  countries:  any  = (countryList  as  any).default;
  step=1
  idtype;
  imagePath;
  imageIdPath;
  imgURL;
  imgIdURL;
  languageindex = "";
  // Image cropper
  imageChangedEvent: any = '';
  croppedImage: any = '';
  originalImage: any = '';
  Loading=null
  dialects
  cellphonecountrycode
  cellphoneareacode
  cellphonenumber
  public newTrainee = {
    id: uuid.v4(),
    accountid:this.appService.getUserAccount().db['account'].id,
    studentAccountId:this.appService.getUserAccount().db['account'].id,
    companyid:"",
    govtidcountry:"",
    govtidtype:"",
    govtissuedid:"",
    dialect:'',
    givenname: "",
    middlename: "",
    surname: "",
    language: "",
    gender: "",
    email:"",
    cellphone:"",
    addressline1:"",
    addressline2:"",
    addresscity:"",
    addressstate:"",
    addresspostalcode:"",
    addresscountry:"",
    socialweb:"",
    socialfacebook:"",
    socialinstagram:"",
    socialtelegram:"",
    socialtwitter:"",
    socialyoutube:"",
    socialskype:"",
    sociallinkedin:"",
    socialpinterest:"",
    socialdropbox:"",
    pollyvoice:"",
    photo: "",
  };
  studentimagetype
  imagetype
  loadedImage=false
  imageTraineeFile;
  imageTraineeIdFile;
  uploadProgress: number;
  uploadMessage
    usermediaid: any;
  constructor(
    private modalCtrl: ModalController,
    private traineesService: TraineesService,
    private appService: AppService,
    public mediaService: MediaService,
    public loadingController: LoadingController
  ) {}

  ngOnInit() {
    // console.log("App Service", this.appService.getConfig())
  }
  
  setAvatar(avatar){
    this.imagetype="AVATAR"
    this.newTrainee.photo=avatar
  }
  
  setLanguage(language){
    
    for (let lang in this.appService.config.languages){
      if (this.appService.config.languages[lang].language === this.newTrainee.language){
        this.dialects=this.appService.config.languages[lang].dialects
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
  
  setPollyVoice(){
    if (this.newTrainee.dialect){
      //console.log("Set Polly")
      for (let lang in this.appService.config.languages){
        if (this.appService.config.languages[lang].language === this.newTrainee.language){
          for (let dialect in this.appService.config.languages[lang].dialects){
            if (this.appService.config.languages[lang].dialects[dialect].dialect === this.newTrainee.dialect){
              //console.log("Dialect", this.appService.config.languages[lang].dialects[dialect])
              for (let voice in this.appService.config.languages[lang].dialects[dialect].voices){
                if (this.appService.config.languages[lang].dialects[dialect].voices[voice].default === true){
                  this.newTrainee.pollyvoice=this.appService.config.languages[lang].dialects[dialect].voices[voice].voice
                }
              }
            }
          }
        }
      }
    }
  }
  
  setCellPhone(){
    this.newTrainee.cellphone="+" + this.cellphonecountrycode + this.cellphoneareacode  + this.cellphonenumber
  }

  uploadTraineePhoto(ev) {
    console.log("ev", ev);
    this.imageTraineeFile = ev.target.files[0];

    var reader = new FileReader();
    this.imagePath = ev;
    reader.readAsDataURL(ev.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }
  
  setStep(direction){
    switch(direction){
      case "forward":
        this.step ++
        break;
      case "back":
        this.step --
        break
    }
  }

  uploadTraineeId(ev) {
    //console.log("ev", ev);
    var reader = new FileReader();
    this.imageIdPath = ev;
    this.imageTraineeIdFile = ev.target.files[0];
    reader.readAsDataURL(this.imageTraineeIdFile);
    reader.onload = (_event) => {
      this.imgIdURL = reader.result;
    };
  }
  getTraineeUploadsPath(filename, extension) {
    return "trainees/" + filename + extension;
  }

  setLanguageIndex(index){
    console.log("index", index)
  }
  
  displayNextButton(){
    if (!this.uploadProgress){
      if (this.studentimagetype){
        switch(this.studentimagetype){
          case "AVATAR":
            if (this.newTrainee.photo){
              return true
            }else{
              return false
            }
            break;
          case "UPLOAD":
            if (this.croppedImage){
              return true
            }else{
              return false
            }
            break;
        }
      }else{
        return false
      }
    }else{
      return false
    }
    
  }

  createNewTrainee() {
    let self = this;
    let imageuuid=uuid.v4()
    let originalFileObj={}
    this.uploadProgress=.2
    this.uploadMessage="Creating Student"
    //let loading=this.presentLoading()
    if (this.studentimagetype === "UPLOAD"){
      this.newTrainee.photo=uuid.v4()
    }
    if (typeof this.originalImage === 'object' && this.studentimagetype==='UPLOAD') { // If an image has been uploaded
      var uploadTemplate={
        filename:this.newTrainee.photo + '.jpg',
        filextension:".jpg",
        direction:'mediauploads',
        image:this.originalImage,
        contentType:"image/jpeg",
        course:{},
        sizeKb:0,
        width:0,
        height:0,
        duration:0,
        meta:{
          name: this.originalImage.name,
          lastModified: this.originalImage.lastModified.toString(),
          size: this.originalImage.size.toString(),
          type: this.originalImage.type,
          givenname: this.newTrainee.givenname,
          middlename: this.newTrainee.middlename,
          surname: this.newTrainee.surname,
          traineeid: this.newTrainee.id,
          photo: this.newTrainee.photo
        }
      }
    }
    this.traineesService.createTrainee(this.newTrainee)// Save trainee data to database
    .then((newtrainee) => {// Save original file to media in folder
      this.uploadProgress=.3
      this.uploadMessage="Saving Original Image"
      if (typeof this.originalImage === 'object' && this.studentimagetype === "UPLOAD"){ // An image was uploaded
        this.mediaService.uploadFile(uploadTemplate)
          .then((res) => {
            this.usermediaid=res['data'].createMedia.id
            this.mediaService.resizeImage(this.croppedImage, 512)
              .then((resizedImage512) => {
                this.uploadProgress=.4
                this.uploadMessage="Saving Large Image"
                uploadTemplate.sizeKb=this.croppedImage['size']
                uploadTemplate.image=resizedImage512
                uploadTemplate.filename=this.newTrainee.photo + '-512.jpg'
                uploadTemplate.direction="mediadownloads"
                uploadTemplate.width=512
                uploadTemplate.height=512
                uploadTemplate.meta.size=resizedImage512['size'].toString()
                this.mediaService.uploadFile(uploadTemplate)
                .then((res) => {
                  this.uploadProgress=.5
                  this.uploadMessage="Saving Medium Image"
                  this.mediaService.resizeImage(this.croppedImage, 256)
                  .then((resizedImage256) => {
                    uploadTemplate.sizeKb=resizedImage256['size']
                    uploadTemplate.image=resizedImage256
                    uploadTemplate.filename=this.newTrainee.photo + '-256.jpg'
                    uploadTemplate.direction="mediadownloads"
                    uploadTemplate.width=256
                    uploadTemplate.height=256
                    uploadTemplate.meta.size=resizedImage256['size'].toString()
                    this.mediaService.uploadFile(uploadTemplate)
                    .then((res) => {
                      this.uploadProgress=.6
                      this.uploadMessage="Saving Small Image"
                      this.mediaService.resizeImage(this.croppedImage, 64)
                      .then((resizedImage64) => {
                          uploadTemplate.sizeKb=resizedImage64['size']
                          uploadTemplate.image=resizedImage64
                          uploadTemplate.filename=this.newTrainee.photo + '-64.jpg'
                          uploadTemplate.direction="mediadownloads"
                          uploadTemplate.width=64
                          uploadTemplate.height=64
                          uploadTemplate.meta.size=resizedImage64['size'].toString()
                        this.mediaService.uploadFile(uploadTemplate)
                        .then((res) => {
                          this.uploadProgress=.7
                          this.uploadMessage="Saving thumbnail Image"
                          this.mediaService.resizeImage(this.croppedImage, 32)
                          .then((resizedImage32) => {
                            uploadTemplate.sizeKb=resizedImage32['size']
                            uploadTemplate.image=resizedImage32
                            uploadTemplate.filename=this.newTrainee.photo + '-32.jpg'
                            uploadTemplate.direction="mediadownloads"
                            uploadTemplate.width=32
                            uploadTemplate.height=32
                            uploadTemplate.meta.size=resizedImage32['size'].toString()
                            this.mediaService.uploadFile(uploadTemplate)
                            .then((res) => {
                              this.uploadMessage="Analyzing image"
                              this.uploadProgress=.8
                              this.appService.setRekognitionFace(this.newTrainee.photo + '.jpg', this.newTrainee.photo)
                              .then((rekognitionFaceResult) => {
                                this.uploadMessage="Saving image analysis"
                                this.uploadProgress=.9
                                
                                this.mediaService.setRekognitionData(this.usermediaid, rekognitionFaceResult['data'].indexFaces, rekognitionFaceResult['data'].detectFaces)
                                .then((rekognitionData) => {
                                  this.uploadMessage="Complete"
                                  this.uploadProgress=1
                                  self.modalCtrl.dismiss();
                                  self.traineesService.getStudents();         
                                })
                              })
                            })
                          })
                        })
                      })
                    })
                  })
                })
              })
            })

      }else{ // No image was uploaded. End of procedure
      this.uploadProgress=1
      this.uploadMessage="Done"
        self.modalCtrl.dismiss();
        //this.Loading.dismiss()
        self.traineesService.getStudents();
      }
      
    })
  }

  // image cropper
  fileChangeEvent(event: any): void {
    this.originalImage=event.target.files[0]
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.loadedImage=true
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  async presentLoading() {
    this.Loading = await this.loadingController.create({
      message: 'Please wait...',
    });
    this.Loading.present()
  }
}
