/** @format */

import { Injectable } from "@angular/core";
// import * as Observable from "zen-observable";
import { AppService } from "../services/app.service";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { Observable } from "rxjs";
import * as _ from "lodash";
import * as uuid from "uuid";
import { LoadingController, NavController } from "@ionic/angular";
import { Location } from "@angular/common";
import API from "@aws-amplify/api";
import { SpinnerService } from "./spinner.service";
// import { getAudioDurationInSeconds } from "get-audio-duration";
// const mp3Duration = require('mp3-duration');

export interface Course {
	getCourses: {
		course: string;
		id: string;
		name: string;
	};
}

@Injectable({
	providedIn: "root",
})
export class CoursesService {
	course;
	courseJson: any;
	courseObservable: any;
	$courses: any[] = [];
	courseid;
	loading;
	extension;
	progressbar = "determinate";
	val1 = new Array(1);
	newCourses;

	coursesObservable = new Observable((observer) => {});

	getTest() {
		return "Test OK";
	}

	constructor(
		public spinnerService: SpinnerService,
		private apollo: Apollo,
		private appservice: AppService,
		public loadingController: LoadingController,
		public navCtrl: NavController
	) {
		/*API.graphql(graphqlOperation(onCreateUser)).subscribe({
       next: (eventData) => console.log(eventData)
    });*/
	}

	getCourseUrl(dir) {
		return (
			this.appservice.config.paths.contentdomain +
			this.appservice.config.paths.coursesbase +
			this.getCourseId() +
			"/" +
			this.getLanguage() +
			this.appservice.config.paths.files[dir]
		);
	}

	getCourseUploadsPath() {
		return (
			this.appservice.config.paths.contentdomain +
			this.appservice.config.paths.coursesbase +
			this.getCourseId() +
			"/uploads/"
		);
	}

	getCourseId() {
		return this.courseid;
	}

	setNewFrame() {
		const blankFrame = this.getBlankFrame();
		blankFrame.id = uuid.v4();
		blankFrame.meta.createdon = Date.now();
		this.course.getCourses.course.frames.push(blankFrame);
		console.log("blankFrame", blankFrame);
		return this.course.getCourses.course.frames.length;
		/*
    // make a copy of the course
    let coursecopy = JSON.parse(JSON.stringify(this.course));

    // create new frame
    let blankframe = this.getBlankFrame();
    // append it to the new course

    // save the course

    this.setUpdateFrameObject(coursecopy.getCourses.course);
    this.saveCourseObject(coursecopy.getCourses.course).then((res) => {
      // load the course
      this.getCourse(this.courseid);
    });
    */
	}

	getBlankFrame() {
		//const copy = { ...this.appservice.config.newcourse.frames[0] };

		const copy = JSON.parse(
			JSON.stringify(this.appservice.config.newcourse.frames[0])
		);

		function eachRecursive(obj) {
			for (const k in obj) {
				if (typeof obj[k] === "object" && obj[k] !== null) {
					eachRecursive(obj[k]);
				} else {
					obj[k] = "";
				}
			}
			return obj;
		}
		return copy;
	}

	getFrame(index, id) {
		//console.log("typeof", typeof this.course);
		if (typeof this.course === "undefined") {
			console.log("Not defined", id);
			this.getCourse(id);
		} else {
			return this.course.getCourses.course.frames[index];
		}
	}

	getLanguage() {
		return this.course.getCourses.course.meta.language;
	}

	getPathToUploads(theFileId, extension) {
		return (
			"courses/" +
			this.getCourseId() +
			this.appservice.config.paths.files.uploads +
			theFileId +
			extension
		);
	}

	getPathToMediaUploads(theFileId, extension) {
		return (
			this.appservice.config.paths.files.mediauploads + theFileId + extension
		);
	}

	getPathToMediaDownloads(theFileId, extension) {
		return (
			this.appservice.config.paths.files.mediadownloads + theFileId + extension
		);
	}

	getPathToThumbnails(theFileId) {
		return (
			this.appservice.config.paths.coursesbase +
			this.getCourseId() +
			"/" +
			this.getLanguage() +
			this.appservice.config.paths.files.thumbnails +
			theFileId +
			"." +
			this.appservice.config.paths.files.thumbnailtype
		);
	}
	getPathToFrames(theFileId) {
		return (
			this.appservice.config.paths.coursesbase +
			this.getCourseId() +
			"/" +
			this.getLanguage() +
			this.appservice.config.paths.files.frames +
			theFileId +
			"." +
			this.appservice.config.paths.files.frametype
		);
	}
	getPathToVideos(theFileId) {
		return (
			this.appservice.config.paths.coursesbase +
			this.getCourseId() +
			"/" +
			this.getLanguage() +
			this.appservice.config.paths.files.frames +
			theFileId +
			"." +
			this.appservice.config.paths.files.framevideotype
		);
	}
	getPathToSpeech() {
		return this.appservice.config.paths.files.mediadownloads;
		/*
    return (
      this.appservice.config.paths.coursesbase +
      this.getCourseId() +
      "/" +
      this.getLanguage() +
      this.appservice.config.paths.files.speech
    );*/
	}

	/*
  getPathToFrames(theFileId, extension) {
    return (
      "courses/" +
      this.getCourseId() +
      "/" + this.getLanguage() + "/" +
      this.appservice.config.paths.files.frames +
      theFileId +  extension
    );
  }*/

	getCourse(id) {
		this.courseid = id;
		this.courseObservable = this.apollo
			.watchQuery({
				query: gql`
					query GetCourse($id: ID!) {
						getCourses(id: $id) {
							id
							name
							course
						}
					}
				`,
				variables: {
					id,
				},
			})
			.valueChanges.subscribe(({ data }) => {
				this.course = data;
				// this.desanititizeCourseForDynamo()
				// this.course.=JSON.parse(data.getCourses.course)
				if (this.course.getCourses) {
					typeof this.course.getCourses.course === "string"
						? (this.course.getCourses.course = JSON.parse(
								this.course.getCourses.course
						  ))
						: // tslint:disable-next-line:no-unused-expression
						  null;
				}
			});
	}

	setUpdateFrame(id) {
		const theReturnPromise = new Promise((resolve) => {
			this.sanititizeForDynamo();
			return this.apollo
				.mutate({
					variables: {
						id,
						course: JSON.stringify(this.course.getCourses.course),
					},
					mutation: gql`
						mutation UpdateCourses($id: ID!, $course: AWSJSON) {
							updateCourses(input: { id: $id, course: $course }) {
								id
							}
						}
					`,
				})
				.subscribe(
					(newCourseId) => {
						resolve(newCourseId);
					} // this.getCourses().subscribe()}
				);
		});
		return theReturnPromise;
	}
	setUpdateFrameObject(obj) {
		this.sanititizeForDynamo();

		const theReturnPromise = new Promise((resolve) => {});
		return theReturnPromise;
	}

	/*
  public getCourses():any {
    console.log("Getting courses")
    const coursesObservable = new Observable(observer => {
      this.coursesSubscription = this.apollo.watchQuery({
        query:gql`
          query {
            listCoursess(limit:1000){
              items{
                name
                course
                account {
                  id
                }
              }
            }
          }
        `
      }).valueChanges.subscribe(({data}) => {
        console.log("Data", data)
      })
    }).subscribe()
  }


  getCourses() {
    console.log("Getting Courses")
    this.apollo
      .watchQuery <any[]> ({
        query:gql`
          query listCourses{
            listCoursess(limit:1000){
              items{
                name
                course
                account {
                  id
                }
              }
            }
          }
        `,

      }).valueChanges.pipe(pluck('data', 'listCoursess', 'items'));
      return this.$courses;

    //console.log("Init courses service")
    /*
    const query = API.graphql(graphqlOperation(`
    query   ListApps{
      listApps{
        items{
          id
          name
          domain
        }
      }
    }
    `)) as Promise<any>;


  } */
	createCourseThumbnail(variables) {
		const theReturnPromise = new Promise((resolve) => {
			const vars = {
				queryStringParameters: {
					name: variables.name,
					id: variables.id,
					thumbnail: variables.thumbnail,
				},
			};

			API.get("viar", "/createCourseThumbnail", vars).then((res) => {
				resolve(res);
			});
		});
		return theReturnPromise;
	}

	setNewCourse(thumbnailfilename, thumbnailoriginal) {
		//console.log("AppService", this.appservice.getUserAccount().db['account'].id)
		const self = this;
		this.appservice.config.newcourse.meta.id = uuid.v4();
		this.appservice.config.newcourse.meta.image.thumbnail = thumbnailfilename;
		this.appservice.config.newcourse.meta.image.thumbnailoriginal = thumbnailoriginal;
		this.appservice.config.newcourse.meta.created = Date.now();
		this.appservice.config.newcourse.frames[0].id = uuid.v4();

		const variables = this.appservice.sanititizeForDynamo({
			accountid: this.appservice.getUserAccount().db["account"].id,
			name: self.appservice.config.newcourse.meta.name.trim(),
			course: JSON.stringify(
				this.appservice.sanititizeForDynamo(self.appservice.config.newcourse)
			),
			code: self.appservice.config.newcourse.meta.code.trim(),
			description: self.appservice.config.newcourse.meta.description.trim(),
			language: self.appservice.config.newcourse.meta.language,
			id: self.appservice.config.newcourse.meta.id,
			passinggrade: parseInt(
				self.appservice.config.newcourse.meta.passinggrade
			),
			coursesAccountId: this.appservice.getUserAccount().db["account"].id,
			thumbnail: self.appservice.config.newcourse.meta.image.thumbnail,
			thumbnailoriginal:
				self.appservice.config.newcourse.meta.image.thumbnailoriginal,
			expiration: self.appservice.config.newcourse.meta.expiration,
		});

		const theReturnPromise = new Promise((resolve) => {
			self.apollo
				.mutate({
					variables,
					mutation: gql`
						mutation CreateCourses(
							$name: String
							$course: AWSJSON
							$code: String
							$description: String
							$language: String
							$id: ID!
							$accountid: ID!
							$coursesAccountId: ID
							$thumbnail: String
							$thumbnailoriginal: String
							$coursesAccountId: ID
							$passinggrade: Int
							$expiration: Int
						) {
							createCourses(
								input: {
									name: $name
									course: $course
									code: $code
									description: $description
									language: $language
									id: $id
									accountid: $accountid
									coursesAccountId: $coursesAccountId
									thumbnail: $thumbnail
									thumbnailoriginal: $thumbnailoriginal
									coursesAccountId: $coursesAccountId
									passinggrade: $passinggrade
									expiration: $expiration
								}
							) {
								id
							}
						}
					`,
				})
				.subscribe((result) => {
					resolve(result);
					/*
          this.createCourseThumbnail(variables).then((res) => {
            resolve(res);
          });*/
				});
		});
		return theReturnPromise;
	}

	setSaveCourse(id, frame) {
		const fileid = uuid.v4();
		const courseFrame = this.course.getCourses.course.frames[frame];
		this.course.getCourses.course.frames[frame].thumbnail = "loading";

		console.log("Frame Type", courseFrame.frametype);
		switch (courseFrame.frametype) {
			case "image":
				// this.spinnerService.present({});
				// Speech
				/*
        this.setSpeechFile(courseFrame).then((res) => {
          // Save Course
        });*/
				this.saveCourse(id).then(() => {
					// Save Thumbnail
					/*
					this.saveThumbnail(id, frame, fileid).then(() => {
						// this.spinnerService.dismiss();
						// save Frame
						this.saveFrame(id, frame, fileid).then(() => {});
					});*/
				});

				break;
			case "video":
				const self = this;
				// console.log("extension", extension)
				// Save Course
				self.course.getCourses.course.frames[frame].thumbnail =
					self.course.getCourses.course.frames[frame].video.thumbnail;
				this.saveCourse(id).then(() => {
					/*
          this.saveVideo(id, frame, fileid, extension).then((res) => {
            console.log("Video encoded", res);
          });*/
					// save Video Thumbnail
					/*this.saveVideoThumbnail(id, frame, fileid, extension).then(
            (res) => {
              console.log("Thumbnail", res)

              self.course.getCourses.course.frames[
                frame
              ].thumbnail = res['request'].output.s3.path.split("/")[
                res['request'].output.s3.path.split("/").length - 1
              ];
              this.setUpdateFrame(id);
              console.log("VIDEO THUMBNAIL RES", res, self.course.getCourses.course.frames[
                frame
              ])

            }
          );*/
				});
				break;
			case "vrVideo":
				// console.log('VRVideo upload');
				this.extension = courseFrame.vrVideo.url.split(".")[1];
				this.saveCourse(id).then(() => {
					/*
					this.saveVrVideoThumbnail(id, frame, fileid, this.extension).then(
						() => {
							// console.log('Video VR thumbnail  complete', id, frame);
						}
					);*/
					this.saveVrVideo(id, frame, fileid, this.extension).then(() => {
						// console.log('Video VR thumbnail  complete', id, frame);
					});
				});
				break;

			case "text":
				// this.spinnerService.present({});
				// Speech
				// frame.thumbnail="loading"
				this.setSpeechFile().then(() => {
					// Save Course
					this.saveCourse(id).then(() => {
						// save Frame
						this.saveFrame(id, frame, fileid).then(() => {});
						// Save Thumbnail
						/*
						this.saveThumbnail(id, frame, fileid).then(() => {
							// this.spinnerService.dismiss();
						});*/
					});
				});
				break;
			case "imagetext":
				// this.spinnerService.present({});
				// Speech
				this.setSpeechFile().then(() => {
					// Save Course
					this.saveCourse(id).then(() => {
						// save Frame
						this.saveFrame(id, frame, fileid).then(() => {});
						// Save Thumbnail
						/*
						this.saveThumbnail(id, frame, fileid).then(() => {
							this.spinnerService.dismiss();
						});*/
					});
				});
				break;
			case "assessment":
				// this.spinnerService.present({});
				// console.log('ASSESSMENT UPLOAD');
				this.setSpeechQuestionFile(courseFrame).then(() => {
					// Save Course
					this.saveCourse(id).then(() => {
						// Save Thumbnail
						/*
						this.saveQuestionThumbnail(id, frame, fileid).then(() => {
							// this.spinnerService.dismiss();
						});*/
					});
				});
				// this.spinnerService.dismiss();
				break;

			case "vrImage":
				// console.log('vrImage upload');
				this.setSpeechFile().then(() => {
					this.saveCourse(id).then(() => {
						this.saveThumbnail(id, frame, fileid).then(() => {});
					});
				});
				break;

			default:
				this.course.getCourses.course.frames[frame].thumbnail = " ";
				break;
		}
		this.setUpdateFrame(id);
	}

	saveThumbnail(id, frame, fileid) {
		const theReturnPromise = new Promise((resolve) => {
			/*
			API.post(
				"viar",
				"/createFrameImage",
				this.getCloudconvertthumbnail(id, frame, fileid)
			).then((res) => {
				this.course.getCourses.course.frames[frame].thumbnail = fileid + ".jpg";
				this.setUpdateFrame(id);
				resolve(res);
			});*/
			//resolve true;
		});
		return theReturnPromise;
	}

	saveQuestionThumbnail(id, frame, fileid) {
		const theReturnPromise = new Promise((resolve) => {
			API.post(
				"viar",
				"/createFrameImage",
				this.getCloudconvertthumbnail(id, frame, fileid)
			).then((res) => {
				this.course.getCourses.course.frames[frame].thumbnail = fileid + ".jpg";
				this.setUpdateFrame(id);
				resolve(res);
			});
		});
		return theReturnPromise;
	}

	saveFrame(id, frame, fileid) {
		// S3
		const theReturnPromise = new Promise((resolve) => {
			/*
			API.post(
				"viar",
				"/createFrameImage",
				this.getCloudconvertimage(id, frame, fileid)
			).then((res) => {
				this.course.getCourses.course.frames[frame].frame =
					fileid + "." + this.appservice.config.paths.files.frametype;
				// + "." + this.appservice.config.paths.files.frametype;
				this.setUpdateFrame(id);
				resolve(res);
			});*/
		});
		return theReturnPromise;
	}
	saveVrVideoThumbnail(id, frame, fileid, extension) {
		// console.log('Save VR video thumbnail');
		const theReturnPromise = new Promise((resolve) => {
			API.post(
				"viar",
				"/createFrameImage",
				this.getCloudconvertvrvideothumbnail(id, frame, fileid)
			).then((res) => {
				this.course.getCourses.course.frames[
					frame
				].thumbnail = res.request.output.s3.path.split("/")[
					res.request.output.s3.path.split("/").length - 1
				];
				this.setUpdateFrame(id);
				resolve(res);
			});
		});
		return theReturnPromise;
	}

	saveVideoThumbnail(id, frame, fileid, extension) {
		const theReturnPromise = new Promise((resolve) => {
			API.post(
				"viar",
				"/createFrameImage",
				this.getCloudconvertvideothumbnail(id, frame, fileid)
			).then((res) => {
				// console.log('saveVideoThumbnail Thumbnail Received', res);

				resolve(res);
			});
		});
		return theReturnPromise;
	}
	saveVideo(id, frame, fileid, extension) {
		// console.log('Save Video', id, frame, fileid, extension);
		const theReturnPromise = new Promise((resolve) => {
			API.post(
				"viar",
				"/createFrameImage",
				this.getCloudconvertvideo(id, frame, fileid, extension)
			).then((res) => {
				// console.log('video  response', res);
				this.course.getCourses.course.frames[frame].frame = fileid + ".mp4";
				this.setUpdateFrame(id);
				resolve(res);
			});
		});
		return theReturnPromise;
	}

	saveVrVideo(id, frame, fileid, extension) {
		// console.log('Save VR Video', id, frame, fileid, extension);
		const theReturnPromise = new Promise((resolve) => {
			API.post(
				"viar",
				"/createFrameImage",
				this.getCloudconvertvrvideo(id, frame, fileid, extension)
			).then((res) => {
				console.log("VR video  response", res);
				this.course.getCourses.course.frames[frame].frame = fileid + ".mp4";
				this.setUpdateFrame(id);
				resolve(res);
			});
		});
		return theReturnPromise;
	}

	saveCourse(id) {
		this.progressbar = "indeterminate";
		this.sanititizeForDynamo();
		const theReturnPromise = new Promise((resolve) => {
			API.post("viar", "/saveCourse", {
				// Save
				body: {
					courseid: id,
					language: this.getLanguage(),
					course: JSON.stringify(this.course.getCourses),
				},
			}).then((res) => {
				this.progressbar = "determinate";
				resolve(res);
			});
		});
		return theReturnPromise;
	}

	saveCourseObject(obj) {
		const theReturnPromise = new Promise((resolve) => {
			API.post("viar", "/saveCourse", {
				// Save
				body: {
					courseid: this.courseid,
					language: this.getLanguage(),
					course: JSON.stringify(obj),
				},
			}).then((res) => {
				resolve(res);
			});
		});
		return theReturnPromise;
	}

	getLanguageLocale() {
		const language = this.course.getCourses.course.meta.language;
		const theReturn = {
			voice: "",
			dialect: "",
			language,
		};
		// tslint:disable-next-line:prefer-for-of
		for (let lang = 0; lang < this.appservice.config.languages.length; lang++) {
			if (this.appservice.config.languages[lang].language === language) {
				for (
					let dialect = 0;
					dialect < this.appservice.config.languages[lang].dialects.length;
					dialect++
				) {
					for (
						let voice = 0;
						voice <
						this.appservice.config.languages[lang].dialects[dialect].voices
							.length;
						voice++
					) {
						if (
							this.appservice.config.languages[lang].dialects[dialect].voices[
								voice
							].default === true
						) {
							theReturn.voice = this.appservice.config.languages[lang].dialects[
								dialect
							].voices[voice];
							theReturn.dialect = this.appservice.config.languages[
								lang
							].dialects[dialect].dialect;
						}
					}
				}
			}
		}
		return theReturn;
	}

	setSpeechFile3(frame) {
		let self = this;
		const theReturnPromise = new Promise((resolve) => {
			let fileuuid = uuid.v4();
			if (frame.audio.string.length > 1) {
				//console.log("COURSE", this.course.getCourses.course.meta.language);
				const locale = this.getLanguageLocale();
				frame.audio.url = fileuuid;
				frame.audio.type = "voiceover";
				frame.audio.language = this.course.getCourses.course.meta.language;
				//frame.audio.language =
				for (let i in this.appservice.config.compression.narration
					.compressions) {
					let key =
						"public/" +
						this.getPathToMediaDownloads(
							fileuuid,
							this.appservice.config.compression.narration.compressions[i]
								.suffix
						);
					const ssmlPrefix = locale.voice["newscaster"]
						? "<amazon:domain name='news'>"
						: null;
					const ssmlSuffix = locale.voice["newscaster"]
						? "</amazon:domain>"
						: null;
					const theText =
						"<speak>" +
						ssmlPrefix +
						frame.audio.string.trim() +
						ssmlSuffix +
						"</speak>";
					const request = {
						body: {
							s3: {
								ContentType: "audio/mpeg",
								ACL: "public-read",
								Key: key,
								Metadata: {
									type: "audio",
									app: "Viarspace 360",
									version: "1.20",
									id: fileuuid,
									voice: locale.voice["voice"],
									languagecode: locale.language + "-" + locale.dialect,
									courseid: this.course.getCourses.id,
								},
							},
							polly: {
								VoiceId: locale.voice["voice"],
								OutputFormat: "mp3",
								LanguageCode: locale.language + "-" + locale.dialect,
								TextType: "ssml",
								Engine: locale.voice["neural"] ? "neural" : "standard",
								Text: theText,
								SampleRate: String(
									this.appservice.config.compression.narration.compressions[i]
										.value
								),
							},
						},
					};
					//console.log("Request", request);
					API.post("viar", "/setSpeech", request)
						.then((res) => {
							resolve(res);
						})
						.catch((err) => {
							console.log("Error while creating audio narration", err);
						});
				}
			} else {
				resolve(null);
			}
		});
		return theReturnPromise;
	}

	setSpeechFile2(frame) {
		// console.log("Audio Course frame", frame.audio, this.appservice.config.paths.files.mediadownloads);
		/*
		const theReturnPromise = new Promise((resolve) => {
			const self = this;

			if (frame.audio.string.length > 1) {
				{
					const locale = this.getLanguageLocale();
					let fileuuid = uuid.v4();

					let key =
						"public/" +
						this.appservice.config.paths.files.mediadownloads +
						fileuuid +
						"-" +
						String(
							this.appservice.config.compression.narration.compressions.high
								.value
						) +
						".mp3";
					const ssmlPrefix = locale.voice["newscaster"]
						? "<amazon:domain name='news'>"
						: null;
					const ssmlSuffix = locale.voice["newscaster"]
						? "</amazon:domain>"
						: null;
					const theText =
						"<speak>" +
						ssmlPrefix +
						frame.audio.string.trim() +
						ssmlSuffix +
						"</speak>";

					const request = {
						body: {
							s3: {
								ContentType: "audio/mpeg",
								ACL: "public-read",
								Key: key,
								Metadata: {
									type: "audio",
									app: "Viarspace 360",
									version: "1.20",
									id: fileuuid,
									voice: locale.voice["voice"],
									languagecode: locale.language + "-" + locale.dialect,
									courseid: this.course.getCourses.id,
								},
							},
							polly: {
								VoiceId: locale.voice["voice"],
								OutputFormat: "mp3",
								LanguageCode: locale.language + "-" + locale.dialect,
								TextType: "ssml",
								Engine: "neural",
								Text: theText,
								SampleRate: String(
									this.appservice.config.compression.narration.compressions.high
										.value
								),
							},
						},
					};

					frame.audio.request = request;
					API.post("viar", "/setSpeech", request)
						.then((res) => {
							frame.audio.response = res;
							frame.audio.type = "voiceover";
							frame.audio.url =
								fileuuid +
								"-" +
								String(
									this.appservice.config.compression.narration.compressions.high
										.value
								) +
								".mp3";
							const audio = new Audio(
								this.appservice.config.paths.contentdomain +
									"public/" +
									this.appservice.config.paths.files.mediadownloads +
									frame.audio.url
							);
							audio.addEventListener("loadeddata", () => {
								frame.audio.duration = audio.duration;
								// console.log('Frame Audio', audio.duration, frame, audio);
							});

							resolve(res);
						})
						.then((res) => {
							// mid
							key =
								"public/" +
								this.appservice.config.paths.files.mediadownloads +
								fileuuid +
								"-" +
								String(
									this.appservice.config.compression.narration.compressions.mid
										.value
								) +
								".mp3";

							const request = {
								body: {
									s3: {
										ContentType: "audio/mpeg",
										ACL: "public-read",
										Key: key,
										Metadata: {
											type: "audio",
											app: "Viarspace 360",
											version: "1.10",
											id: fileuuid,
											voice: locale.voice["voice"],
											languagecode: locale.language + "-" + locale.dialect,
											courseid: this.course.getCourses.id,
										},
									},
									polly: {
										VoiceId: locale.voice["voice"],
										OutputFormat: "mp3",
										LanguageCode: locale.language + "-" + locale.dialect,
										TextType: "ssml",
										Engine: "neural",
										Text: theText,
										SampleRate: String(
											this.appservice.config.compression.narration.compressions
												.mid.value
										),
									},
								},
							};
							frame.audio.request = request;
							API.post("viar", "/setSpeech", request);
						})
						.then((res) => {
							// low
							key =
								"public/" +
								this.appservice.config.paths.files.mediadownloads +
								fileuuid +
								"-" +
								String(
									this.appservice.config.compression.narration.compressions.low
										.value
								) +
								".mp3";

							const request = {
								body: {
									s3: {
										ContentType: "audio/mpeg",
										ACL: "public-read",
										Key: key,
										Metadata: {
											type: "audio",
											app: "Viarspace 360",
											version: "1.10",
											id: fileuuid,
											voice: locale.voice["voice"],
											languagecode: locale.language + "-" + locale.dialect,
											courseid: this.course.getCourses.id,
										},
									},
									polly: {
										VoiceId: locale.voice["voice"],
										OutputFormat: "mp3",
										LanguageCode: locale.language + "-" + locale.dialect,
										TextType: "ssml",
										Engine: "neural",
										Text: theText,
										SampleRate: String(
											this.appservice.config.compression.narration.compressions
												.low.value
										),
									},
								},
							};
							frame.audio.request = request;
							API.post("viar", "/setSpeech", request);
						});
				}
			} else {
				resolve(null);
			}
		});

		return theReturnPromise;
		*/
	}

	setSpeechFile() {
		const theReturnPromise = new Promise((resolve) => {
			/*
      API.post("viar", "/setSpeech", {
        body: {
          path: this.getPathToSpeech(),
          speech: courseFrame.audio.string.replace(/<[^>]*>/g, ""),
          language: this.course.getCourses.course.meta.language
        }
      }).then((res) => {
        courseFrame.audio.url = res["file"] + ".mp3";
        resolve(res);
      });
      */
			resolve(null);
		});

		return theReturnPromise;
	}

	setSpeechQuestionFile(courseFrame) {
		const theReturnPromise = new Promise((resolve) => {
			API.post("viar", "/setSpeech", {
				body: {
					path: this.getPathToSpeech(),
					speech: courseFrame.assessment.question.string.replace(
						/<[^>]*>/g,
						""
					),
					language: this.course.getCourses.course.meta.language,
				},
			}).then((res) => {
				courseFrame.audio.url = res.file + ".mp3";
				resolve(res);
			});
		});
		return theReturnPromise;
	}

	/*
  getCloudconvertimage(id, frame, fileid) {
    return {
      body: {
        courseid: id,
        language: this.course.getCourses.course.meta.language,
        cloudconvertRequest: {
          inputformat: "website",
          outputformat: "png",
          input: "url",
          file:
            this.appservice.config.paths.contentdomain +
            this.appservice.config.paths.coursesbase +
            this.appservice.config.paths.framemaker +
            "?courseid=" +
            id +
            "&language=" +
            this.course.getCourses.course.meta.language +
            "&frame=" +
            frame,
          converteroptions: {
            resize: "800x450",
            resizemode: "scale",
            resizeenlarge: false,
            grayscale: null,
            strip_metatags: false,
            density: 300,
            rotate: null,
            page_range: null,
            disable_alpha: true,
            command: null,
            no_images: null,
            no_background: null,
            disable_javascript: null,
            javascript_delay: 200,
            zoom: 1,
            screen_width: "800",
            screen_height: "450",
            use_chrome: "1",
            http_headers: null
          },
          output: {
            s3: {
              accesskeyid: "",
              region: "us-west-2",
              bucket: "212-dev",
              path:
                this.appservice.config.paths.coursesbase +
                id +
                "/" +
                this.course.getCourses.course.meta.language +
                this.appservice.config.paths.assetspath +
                fileid +
                ".png",
              secretaccesskey: ""
            }
          },
          save: true
        }
      }
    };
  }*/
	getCloudconvertthumbnail(id, frame, fileid) {
		const pathToFramemaker =
			this.appservice.config.paths.contentdomain +
			this.appservice.config.paths.coursesbase +
			this.appservice.config.paths.files.framemaker +
			this.getLanguage() +
			"/" +
			frame +
			"/" +
			id;
		console.log(
			"PathToFramemaker",
			pathToFramemaker,
			this.getPathToThumbnails(fileid)
		);
		return {
			body: {
				courseid: id,
				language: this.course.getCourses.course.meta.language,
				cloudconvertRequest: {
					inputformat: "website",
					outputformat: this.appservice.config.paths.files.thumbnailtype,
					input: "url",
					file: pathToFramemaker,

					converteroptions: {
						resize: this.appservice.config.paths.files.thumbnailsize,
						resizemode: "scale",
						resizeenlarge: false,
						grayscale: null,
						strip_metatags: false,
						density: 300,
						rotate: null,
						page_range: null,
						disable_alpha: true,
						command: null,
						no_images: null,
						no_background: null,
						disable_javascript: null,
						javascript_delay: 5000,
						zoom: 1,
						screen_width: "800",
						screen_height: "450",
						use_chrome: "1",
						http_headers: null,
					},
					output: {
						s3: {
							accesskeyid: "",
							region: this.appservice.config.paths.files.s3region,
							bucket: this.appservice.config.paths.files.s3bucket,
							path: this.getPathToThumbnails(fileid),
							secretaccesskey: "",
						},
					},
					save: true,
				},
			},
		};
	}
	// Image
	getCloudconvertimage(id, frame, fileid) {
		const pathToFramemaker =
			this.appservice.config.paths.contentdomain +
			this.appservice.config.paths.coursesbase +
			this.appservice.config.paths.files.framemaker +
			this.getLanguage() +
			"/" +
			frame +
			"/" +
			id;

		return {
			body: {
				courseid: id,
				language: this.course.getCourses.course.meta.language,
				cloudconvertRequest: {
					inputformat: "website",
					outputformat: this.appservice.config.paths.files.frametype,
					input: "url",
					file: pathToFramemaker,

					converteroptions: {
						resize: this.appservice.config.paths.files.framesize,
						resizemode: "scale",
						resizeenlarge: false,
						grayscale: null,
						strip_metatags: false,
						density: 300,
						rotate: null,
						page_range: null,
						disable_alpha: true,
						command: null,
						no_images: null,
						no_background: null,
						disable_javascript: null,
						javascript_delay: 5000,
						zoom: 1,
						screen_width: "800",
						screen_height: "450",
						use_chrome: "1",
						http_headers: null,
					},
					output: {
						s3: {
							accesskeyid: "",
							region: this.appservice.config.paths.files.s3region,
							bucket: this.appservice.config.paths.files.s3bucket,
							path: this.getPathToFrames(fileid),
							secretaccesskey: "",
						},
					},
					save: true,
				},
			},
		};
	}

	// VIDEO THUMBNAIL
	getCloudconvertvideothumbnail(id, frame, fileid) {
		// console.log('pathToVideo', id, frame, fileid);
		const pathToVideo =
			this.getCourseUploadsPath() +
			this.getFrame(frame, this.courseid).video.url;
		return {
			body: {
				courseid: id,
				language: this.course.getCourses.course.meta.language,
				cloudconvertRequest: {
					mode: "info",
					input: "download",
					file: pathToVideo,
					converteroptions: {
						thumbnail_format: this.appservice.config.paths.files.thumbnailtype,
						thumbnail_size: this.appservice.config.paths.files.thumbnailsize,
					},
					output: {
						s3: {
							accesskeyid: "",
							region: this.appservice.config.paths.files.s3region,
							bucket: this.appservice.config.paths.files.s3bucket,
							path: this.getPathToThumbnails(fileid),
							secretaccesskey: "",
						},
					},
					save: true,
				},
			},
		};
	}
	// VR Video Thumbnail
	getCloudconvertvrvideothumbnail(id, frame, fileid) {
		// console.log('pathToVideo', id, frame, fileid);
		const pathToVideo =
			this.getCourseUploadsPath() +
			this.getFrame(frame, this.courseid).vrVideo.url;
		return {
			body: {
				courseid: id,
				language: this.course.getCourses.course.meta.language,
				cloudconvertRequest: {
					mode: "info",
					input: "download",
					file: pathToVideo,
					converteroptions: {
						thumbnail_format: this.appservice.config.paths.files.thumbnailtype,
						thumbnail_size: this.appservice.config.paths.files.thumbnailsize,
					},
					output: {
						s3: {
							accesskeyid: "",
							region: this.appservice.config.paths.files.s3region,
							bucket: this.appservice.config.paths.files.s3bucket,
							path: this.getPathToThumbnails(fileid),
							secretaccesskey: "",
						},
					},
					save: false,
					wait: false,
				},
			},
		};
	}
	// VR Video
	getCloudconvertvrvideo(id, frame, fileid, extension) {
		// console.log('pathToVRVideo', id, frame, fileid, extension);
		const pathToVideo =
			this.getCourseUploadsPath() +
			this.getFrame(frame, this.courseid).vrVideo.url;
		// console.log('extension', extension);
		return {
			body: {
				courseid: id,
				language: this.course.getCourses.course.meta.language,
				cloudconvertRequest: {
					inputformat: extension,
					outputformat: this.appservice.config.paths.files.videotype,
					input: "download",
					file: pathToVideo,
					converteroptions: {
						video_codec: "H264",
						video_crf: "23",
						video_qscale: -1,
						video_bitrate: 1024,
						video_resolution: this.appservice.config.paths.files
							.vrvideoresolution,
						faststart: true,
						audio_codec: "AAC",
						audio_qscale: -1,
						audio_bitrate: 128,
						audio_channels: null,
						audio_frequency: 44100,
						strip_metatags: false,
					},
					output: {
						s3: {
							accesskeyid: "",
							region: this.appservice.config.paths.files.s3region,
							bucket: this.appservice.config.paths.files.s3bucket,
							path: this.getPathToVideos(fileid),
							secretaccesskey: "",
						},
					},
					save: false,
					wait: false,
				},
			},
		};
	}

	// Video
	getCloudconvertvideo(id, frame, fileid, extension) {
		//  console.log('pathToVideo', id, frame, fileid, extension);
		const pathToVideo =
			this.getCourseUploadsPath() +
			this.getFrame(frame, this.courseid).video.url;
		// console.log('extension', extension);
		return {
			body: {
				courseid: id,
				language: this.course.getCourses.course.meta.language,
				cloudconvertRequest: {
					inputformat: extension,
					outputformat: this.appservice.config.paths.files.videotype,
					input: "download",
					file: pathToVideo,
					converteroptions: {
						video_codec: "H264",
						video_crf: "23",
						video_qscale: -1,
						video_bitrate: 1024,
						video_resolution: this.appservice.config.paths.files
							.videoresolution,
						faststart: true,
						audio_codec: "AAC",
						audio_qscale: -1,
						audio_bitrate: 128,
						audio_channels: null,
						audio_frequency: 44100,
						strip_metatags: false,
					},
					output: {
						s3: {
							accesskeyid: "",
							region: this.appservice.config.paths.files.s3region,
							bucket: this.appservice.config.paths.files.s3bucket,
							path: this.getPathToVideos(fileid),
							secretaccesskey: "",
						},
					},
					save: false,
					wait: false,
				},
			},
		};
	}

	desanititizeCourseForDynamo() {
		eachRecursive(this.course.getCourses.course);

		function eachRecursive(obj) {
			for (const k in obj) {
				if (typeof obj[k] === "object" && obj[k] !== null) {
					eachRecursive(obj[k]);
				} else if (obj[k] === " ") {
					obj[k] = "";
				}
			}
		}
	}

	sanititizeForDynamo() {
		eachRecursive(this.course.getCourses.course);

		function eachRecursive(obj) {
			for (const k in obj) {
				if (typeof obj[k] === "object" && obj[k] !== null) {
					eachRecursive(obj[k]);
				} else if (obj[k] === "") {
					try {
						if (typeof obj[k] === "string") {
							obj[k] = " ";
						}
					} catch {
						console.log("ERROR CONVERTING TO DYNAMO", obj[k], k);
					}
				}
			}
		}
	}

	setAllAnswersToFalse(frame) {
		frame.assessment.answer1.correct =
			frame.assessment.answer1.correct === " "
				? false
				: frame.assessment.answer1.correct;
		frame.assessment.answer2.correct =
			frame.assessment.answer2.correct === " "
				? false
				: frame.assessment.answer2.correct;
		frame.assessment.answer3.correct =
			frame.assessment.answer3.correct === " "
				? false
				: frame.assessment.answer3.correct;
		frame.assessment.answer4.correct =
			frame.assessment.answer4.correct === " "
				? false
				: frame.assessment.answer4.correct;
		frame.assessment.answer5.correct =
			frame.assessment.answer5.correct === " "
				? false
				: frame.assessment.answer5.correct;
	}

	sanititizeForDynamoObject(object) {
		eachRecursive(object);

		function eachRecursive(obj) {
			for (const k in obj) {
				if (typeof obj[k] === "object" && obj[k] !== null) {
					eachRecursive(obj[k]);
				} else if (obj[k] === "") {
					obj[k] = " ";
				}
			}
		}
	}

	unSanititizeForDynamoObject(object) {
		eachRecursive(object);

		function eachRecursive(obj) {
			for (const k in obj) {
				if (typeof obj[k] === "object" && obj[k] !== null) {
					eachRecursive(obj[k]);
				} else if (obj[k] === " ") {
					obj[k] = "";
				}
			}
		}
	}

	setRemoveFrame(element) {
		const self = this;
		// console.log('Removing index', element);
		// this.course.getCourses.course.frames.splice(index, 1);
		remove(element);
		// tslint:disable-next-line:no-shadowed-variable
		function remove(element) {
			const index = self.course.getCourses.course.frames.indexOf(element);

			if (index !== -1) {
				self.course.getCourses.course.frames.splice(index, 1);
			}
		}
		this.saveCourse(this.courseid).then(() => {
			self.setUpdateFrame(self.courseid);
			this.navCtrl.back();
		});
	}
}
