/** @format */

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import {
	AmplifyAngularModule,
	AmplifyService,
	AmplifyIonicModule,
} from "aws-amplify-angular";
//import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from "@angular/common/http";

import { appsyncClient } from "./aws";
import { ApolloModule, Apollo } from "apollo-angular";

import { QRCodeModule } from "angularx-qrcode";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FivFeatureDiscoveryModule } from "@fivethree/core";

import { CourseNewPageModule } from "./courses/course-new/course-new.module";
import { TraineeNewPageModule } from "./trainees/trainee-new/trainee-new.module";
import { NewaccountuserPageModule } from "./accountusers/newaccountuser/newaccountuser.module";
import { MglTimelineModule } from "angular-mgl-timeline";
import { RegistrationNewPageModule } from "./registrations/registration-new/registration-new.module";
import { ImageUploadComponent } from "src/app/components/image-upload/image-upload.component";
import { NewPageModule } from "./devices/new/new.module";
import { OrderModule } from "ngx-order-pipe";
import { VideoLoadingModalPageModule } from "src/app/courses/course/frame/video-loading-modal/video-loading-modal.module";
//import { OrderModule } from 'ngx-order-pipe';

@NgModule({
	declarations: [AppComponent, ImageUploadComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		AmplifyIonicModule,
		AppRoutingModule,
		AmplifyAngularModule,
		HttpClientModule,
		ApolloModule,
		BrowserAnimationsModule,
		FivFeatureDiscoveryModule,
		CourseNewPageModule,
		TraineeNewPageModule,
		RegistrationNewPageModule,
		MglTimelineModule,
		NewPageModule,
		NewaccountuserPageModule,
		QRCodeModule,
		OrderModule,
		VideoLoadingModalPageModule,
	],
	providers: [
		StatusBar,
		SplashScreen,
		AmplifyService,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
	constructor(apollo: Apollo) {
		apollo.setClient(appsyncClient);
	}
}
