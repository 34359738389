/** @format */

import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./services/auth-guard.service";
import { TraineePage } from "./trainees/trainee/trainee.page";
const routes: Routes = [
	{
		path: "",
		redirectTo: "home",
		pathMatch: "full",
	},
	{
		path: "devices",
		loadChildren: "./devices/devices.module#DevicesPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "devices/new",
		loadChildren: "./devices/new/new.module#NewPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "home",
		loadChildren: () =>
			import("./home/home.module").then((m) => m.HomePageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "list",
		loadChildren: () =>
			import("./list/list.module").then((m) => m.ListPageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "courses",
		loadChildren: "./courses/courses.module#CoursesPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "courses/course/:id",
		loadChildren: "./courses/course/course.module#CoursePageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "courses/course/:id/frame/:index",
		loadChildren: "./courses/course/frame/frame.module#FramePageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "trainees",
		loadChildren: "./trainees/trainees.module#TraineesPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "registrations",
		loadChildren:
			"./registrations/registrations.module#RegistrationsPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "registrations/registration/:id",
		loadChildren:
			"./registrations/registration/registration.module#RegistrationPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "admin",
		loadChildren: "./admin/admin.module#AdminPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "login",
		loadChildren: "./login/login.module#LoginPageModule",
	},
	{
		path: "course-new",
		loadChildren: "./courses/course-new/course-new.module#CourseNewPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "course",
		loadChildren: "./courses/course/course.module#CoursePageModule",
	},
	{
		path: "course/edit/:id",
		loadChildren: "./courses/edit/edit.module#EditPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "trainees/trainee/:id",
		canActivate: [AuthGuardService],
		loadChildren: "./trainees/trainee/trainee.module#TraineePageModule",
	},
	{
		path: "store",
		loadChildren: "./store/store.module#StorePageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "courses/store/product/:id",
		loadChildren:
			"./store/store-product/store-product.module#StoreProductPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "store/create/:category/:courseid",
		loadChildren:
			"./store/store-create/store-create.module#StoreCreatePageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "course/qrcode/:id",
		loadChildren:
			"./courses/course-qrcode/course-qrcode.module#CourseQrcodePageModule",
	},

	{
		path: "trainee-new",
		loadChildren:
			"./trainees/trainee-new/trainee-new.module#TraineeNewPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "registration-new",
		loadChildren:
			"./registrations/registration-new/registration-new.module#RegistrationNewPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "registration",
		loadChildren:
			"./registrations/registration/registration.module#RegistrationPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "trainee",
		loadChildren: "./trainees/trainee/trainee.module#TraineePageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "info",
		loadChildren: "./trainees/trainee/info/info.module#InfoPageModule",
	},
	{
		path: "newuser",
		loadChildren: "./users/new/new.module#NewPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "accountusers",
		loadChildren: "./accountusers/accountusers.module#AccountusersPageModule",
		canActivate: [AuthGuardService],
	},
	{
		path: "newaccountuser",
		loadChildren:
			"./accountusers/newaccountuser/newaccountuser.module#NewaccountuserPageModule",
	},
	{ path: "test", loadChildren: "./test/test.module#TestPageModule" },
	{
		path: "course-store",
		loadChildren:
			"./courses/course-store/course-store.module#CourseStorePageModule",
	},
	{
		path: "courses-list",
		loadChildren:
			"./courses/courses-list/courses-list.module#CoursesListPageModule",
	},
	{
		path: "courses-tabs",
		loadChildren:
			"./courses/courses-tabs/courses-tabs.module#CoursesTabsPageModule",
	},
	{
		path: "video-loading-modal",
		loadChildren:
			"./courses/course/frame/video-loading-modal/video-loading-modal.module#VideoLoadingModalPageModule",
		canActivate: [AuthGuardService],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
