import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

const endpoint =
  'https://qpv26xouofeujaz56xhwcwv5le.appsync-api.us-west-2.amazonaws.com/graphql';

export const appsyncClient: any = new AWSAppSyncClient({
  disableOffline: true,
  url: endpoint,
  region: 'us-west-2',
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: 'da2-mshfv2vvafehrclsnrhs3docja',
  },
});