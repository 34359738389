import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { CoursesService } from '../../services/courses.service'
import { AppService } from '../../services/app.service'
import { MediaService } from '../../services/media.service'
import { ImageCroppedEvent } from 'ngx-image-cropper';
import * as uuid from 'uuid';
import { Storage, API } from 'aws-amplify';
import { NavParams, IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-course-new',
  templateUrl: './course-new.page.html',
  styleUrls: ['./course-new.page.scss'],
})
export class CourseNewPage implements OnInit {
  // @ts-ignore
  @ViewChild('stepSlider') stepSlider: IonSlides;
previewimage
progressbarpercent=0
 progressbarmessage: string;
rawimage
isImageLoaded
loading
courseimagetype
imageChangedEvent: any = '';
croppedImage: any = '';
step=1
stepSliderOptions={
  slidesPerView:1,
  initialSlide: 1,
}
uploadProgress=0
    courseavatar: any;
   
  constructor(
    public modalController: ModalController, 
    public coursesService:CoursesService, 
    public loadingController: LoadingController,
    private mediaService: MediaService,
    public appService:AppService) { }
  spinner=false
  passingGradesOrdered=[]
  
  updateSlider(){
    //console.log("Slider",this.stepSlider, this.step)
    this.stepSlider.slideTo(this.step);
  }
  
  setStep(direction){
    switch(direction){
      case "forward":
        this.step ++
        break;
      case "back":
        this.step --
        break
    }
  }


    // Image Cropper
    fileChangeEvent(event: any): void {
        //console.log("Event", event)
        this.imageChangedEvent = event;
        this.rawimage=event.target.files[0]
        //console.log("imageChangedEvent", imageChangedEvent, rawimage)
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        //this.rawimage = event.base64;
        
        //console.log("EVENT",event, this.croppedImage)
    }
    imageLoaded(ev) {
      this.isImageLoaded=true
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed(err) {
      console.log("Error uploading image", err)
        // show message
    }
    // end image Cropper
    
  ngOnInit() {
    // console.log("Media service", this.mediaService.test())
    this.appService.config.newcourse.meta.name=""
    this.appService.config.newcourse.meta.code=""
    this.appService.config.newcourse.meta.description=""
    this.passingGradesOrdered=this.appService.config.passinggrades.sort((a, b) => a - b)
    //console.log("appService", this.appService.config.avatars.courses)
    
  }

  dismiss() {
     this.modalController.dismiss({
      'dismissed': true
    });
  }
  
  onSelectFile(ev){
    /*
    console.log("image event", ev.target.files)
    let reader = new FileReader()
    let file=ev.target.files[0]
    this.rawimage=file
    console.log("Setting raw", this.rawimage)
    this.previewimage=reader.readAsDataURL(ev.target.files[0])*/
  }
  
  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: "Creating Course..."
    });
    await this.loading.present();
  }
  
  createCourse2(){
    this.progressbarpercent=.10;
    this.progressbarmessage="Creating Course"
    let self=this
    let imageuuid
    if (this.courseimagetype === "UPLOAD"){
      imageuuid=uuid.v4()
    }
    if (this.courseimagetype === "AVATAR"){
      this.progressbarpercent=.90;
      imageuuid=this.courseavatar.name
    }
    let originalFileObj={}
    // let loading=this.presentLoading()
    this.coursesService.setNewCourse(imageuuid, imageuuid)// Save to database
    .then((res) => {
      
      if (this.courseimagetype === "UPLOAD"){// There is an image
      this.progressbarpercent=.25;
      this.progressbarmessage="Saving Original Image"; 
      var uploadTemplate={
          filename:imageuuid+ '.jpg',
          filextension:".jpg",
          direction:'mediauploads',
          image:this.rawimage,
          contentType:"image/jpeg",
          course:{},
          sizeKb:0,
          width:0,
          height:0,
          duration:0,
          meta:{
            name: this.rawimage.name,
            lastModified: this.rawimage.lastModified.toString(),
            size: this.rawimage.size.toString(),
            type: this.rawimage.type,
          }
        }
        this.mediaService.uploadFile(uploadTemplate)
        .then((res) => {
          this.progressbarpercent=.50;
          this.progressbarmessage="Saving Large Image"
          this.mediaService.resizeImage(this.croppedImage, 512)
          .then((resizedImage512) => {
            uploadTemplate.sizeKb=this.croppedImage['size']
            uploadTemplate.image=resizedImage512
            uploadTemplate.filename=imageuuid + '-512.jpg'
            uploadTemplate.direction="mediadownloads"
            uploadTemplate.width=512
            uploadTemplate.height=512
            uploadTemplate.meta.size=resizedImage512['size'].toString()
            this.mediaService.uploadFile(uploadTemplate)
          })
          .then((res) => {
            this.progressbarpercent=.75;
          this.progressbarmessage="Saving Medium Image"
            this.mediaService.resizeImage(this.croppedImage, 256)
            .then((resizedImage256) => {
              uploadTemplate.sizeKb=resizedImage256['size']
              uploadTemplate.image=resizedImage256
              uploadTemplate.filename=imageuuid + '-256.jpg'
              uploadTemplate.direction="mediadownloads"
              uploadTemplate.width=256
              uploadTemplate.height=256
              uploadTemplate.meta.size=resizedImage256['size'].toString()
              this.mediaService.uploadFile(uploadTemplate)
              .then((res) => {
                this.progressbarpercent=.90;
          this.progressbarmessage="Saving Small Image"
                this.mediaService.resizeImage(this.croppedImage, 64)
                .then((resizedImage64) => {
                  uploadTemplate.sizeKb=resizedImage64['size']
                  uploadTemplate.image=resizedImage64
                  uploadTemplate.filename=imageuuid + '-64.jpg'
                  uploadTemplate.direction="mediadownloads"
                  uploadTemplate.width=64
                  uploadTemplate.height=64
                  uploadTemplate.meta.size=resizedImage64['size'].toString()
                  this.mediaService.uploadFile(uploadTemplate)
                  .then((res) => {
                    this.mediaService.resizeImage(this.croppedImage, 32)
                    .then((resizedImage32) => {
                      uploadTemplate.sizeKb=resizedImage64['size']
                      uploadTemplate.image=resizedImage64
                      uploadTemplate.filename=imageuuid + '-32.jpg'
                      uploadTemplate.direction="mediadownloads"
                      uploadTemplate.width=32
                      uploadTemplate.height=32
                      uploadTemplate.meta.size=resizedImage64['size'].toString()
                      this.mediaService.uploadFile(uploadTemplate)
                      .then((res) => {
                        this.progressbarpercent=100;
                        this.progressbarmessage="Preparing"
                        setTimeout(function () {
                          self.dismiss();
                        }, 2000);
                      })
                    })
                  })
                })
              })
            })
          })
        })
      } else { 
        self.dismiss(); 
      }
    })
  }
  
  createCourse() {
    this.spinner=true
    let self=this
    const theFileId = uuid.v4();
    let thumbnailsize=200
    let thumbnailoriginalfilename=theFileId + '.jpg'
    let thumbnailfilename=theFileId + '-' + thumbnailsize + '.jpg'
    this.presentLoading()
    this.coursesService.setNewCourse(thumbnailfilename, thumbnailfilename)
    .then(function(res){
      let theFileName=self.coursesService.getPathToMediaUploads(theFileId, '.jpg');
      let theFileNameSquareSmall=self.coursesService.getPathToMediaDownloads(theFileId + '-' + thumbnailsize, '.jpg');
      //console.log("theFileName", theFileName, "theFileNameSquareSmall", theFileNameSquareSmall)
      
      Storage.put(theFileName, self.rawimage, {
        progressCallback(progress) {
          const value = Math.round((progress.loaded / progress.total) * 100);
            self.uploadProgress = value / 100;
            console.log("Progress 1", progress)
            if (self.uploadProgress === 1){
              self.uploadProgress=0;
              var b64img = self.croppedImage.replace(/^data:image\/\w+;base64,/, "");
              var buf = new Buffer(b64img, 'base64');
              
              Storage.put(theFileNameSquareSmall, buf, {
                progressCallback(progress) {
                  console.log("Progress 2", progress)
                  const value = Math.round((progress.loaded / progress.total) * 100);
                    self.uploadProgress = self.uploadProgress + value / 100
                    if (self.uploadProgress === 1){
                      // Write to database
                      setTimeout(function () {
                        self.loading.dismiss()
                        self.spinner=false
                        self.dismiss();
                      }, 2000);
                      
                    }
                  console.log("Progress", progress)
                },
                contentType: 'image/jpeg'
              });
              
              
            }
          console.log("Progress", progress)
        },
        contentType: 'image/jpeg'
      });
      
      
    })
    
  }

}
