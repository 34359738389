/** @format */

import { Component, OnInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { Subscription, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ModalController } from "@ionic/angular";
import { AppService } from "../../services/app.service";
import { TraineesService } from "../../services/trainees.service";
import { OrderPipe } from "ngx-order-pipe";
import { API } from "aws-amplify";
import * as uuid from "uuid";
import { UniqueDirectiveNames } from "graphql/validation/rules/UniqueDirectiveNames";
@Component({
	selector: "app-registration-new",
	templateUrl: "./registration-new.page.html",
	styleUrls: ["./registration-new.page.scss"],
})
export class RegistrationNewPage implements OnInit {
	loading: boolean;
	students: any;
	student;
	courses: any;
	course;
	courseid: "";
	studentid;
	companyid;
	studentlanguage;
	spinner = false;
	step = 1;
	theCourseList: any;
	theStudentsList;
	customizecompression: false;
	theStudent;

	studentsSubscription: Observable<any>;
	coursesSubscription: Observable<any>;
	registrationSubscription: Observable<any>;
	studentdialect: any;
	studentpollyvoice: any;
	compressions: {};
	videoCompressions;
	audioCompressions;
	imageCompressions;
	objectKeys = Object.keys;

	constructor(
		private apollo: Apollo,
		private appService: AppService,
		private traineesService: TraineesService,
		private modalController: ModalController,
		private orderPipe: OrderPipe
	) {
		//this.theStudentsList = traineesService.$students;
	}
	ngOnInit() {
		this.traineesService.getStudents();

		this.compressions = this.appService.config.compression;

		this.videoCompressions = this.orderPipe.transform(
			this.compressions["video"].compressions,
			"order"
		);
		this.audioCompressions = this.orderPipe.transform(
			this.compressions["narration"].compressions,
			"order"
		);
		this.imageCompressions = this.orderPipe.transform(
			this.compressions["images"].compressions,
			"order"
		);
		//console.log("Compressions", this.compressions);
		/*
    this.traineesService.getStudents()
    .then((students) => {
      this.studentList=students
    })*/
		/*
    this.studentsSubscription = this.apollo
      .watchQuery<any>({
        query: listStudentsQuery,
        variables:{
          id:this.appService.getUserAccount().db['account'].id
        }
      })
      .valueChanges.pipe(map(({ data }) => data.getAccount.students.items));
    */
		this.coursesSubscription = this.apollo
			.watchQuery<any>({
				query: this.listCoursesQuery,
				fetchPolicy: "network-only",
				variables: {
					id: this.appService.getUserAccount().db["account"].id,
				},
			})
			.valueChanges.pipe(map(({ data }) => data.getAccount.courses.items));
	}

	dismiss() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

	setStep(direction) {
		switch (direction) {
			case "forward":
				this.step++;
				break;
			case "back":
				this.step--;
				break;
		}
	}
	setStudent(student) {
		this.student = student;
	}
	setCourse(course) {
		this.course = course;
	}
	setGeneralCompression(compression) {
		console.log("Compression", compression.detail.value);
		for (let i in this.compressions) {
			// console.log("Compressions", i, this.compressions[i].default);
			this.compressions[i].default = compression.detail.value;
		}
		console.log(this.compressions);
	}

	setRegistration() {
		let theLaunchCode = String(
			Math.floor(Math.random() * (9999999 - 1000000 + 1))
		).substr(0, 5);
		this.spinner = true;
		let students = this.traineesService.$students;
		for (let studentobj in students) {
			//console.log(">>>",students[studentobj].student.id, this.studentid)

			if (students[studentobj].student.id === this.studentid) {
				this.theStudent = students[studentobj].student;
				this.companyid = students[studentobj].student.companyid;
				this.studentlanguage = students[studentobj].student.language;
				this.studentdialect = students[studentobj].student.dialect;
				this.studentpollyvoice = students[studentobj].student.pollyvoice;
			}
		}
		/*
    this.studentsSubscription.subscribe(
      (students) => {
        for (let studentobj in students){
          //console.log("studentobj", studentobj, students[studentobj])
          if (students[studentobj].id === this.studentid){
            this.companyid=students[studentobj].companyid
            this.studentlanguage=students[studentobj].language
          }
        }
      }
    )*/

		this.apollo
			.query({
				query: this.getCourse,
				variables: {
					courseid: this.courseid,
				},
			})
			.subscribe(({ data }) => {
				let theCourse = JSON.parse(data["getCourses"].course);
				theCourse.meta.author = this.appService.userAccount.user;
				theCourse.meta.registration = {
					id: uuid.v4(),
					registrationdate: Date.now(),
					launchcode: theLaunchCode,
					accountid: this.appService.getUserAccount().db["account"].id,
					userid: this.appService.getUserAccount().auth["attributes"].sub,
					expirationdate:
						Date.now() +
						Number(theCourse.meta.expiration) * 24 * 60 * 60 * 1000,
					companyid: this.companyid,
				};
				//theCourse.meta.registration.date = Date.now();
				//theCourse.meta.registration.launchcode = theLaunchCode;
				theCourse.meta.compressions = this.compressions;
				theCourse.meta.currentframe = 0;
				/*theCourse.meta.expiration =
						Date.now() + Number(theCourse.meta.expiration) * 24 * 60 * 60 * 1000;*/
				theCourse.meta.trainee = {
					student: this.theStudent,
					language: this.studentlanguage,
					pollyvoice: this.studentpollyvoice,
					dialect: this.studentdialect,
				};
				console.log("The Course", theCourse);

				API.post("viar", "/setRegistration", { body: theCourse })
					.then((res) => {
						this.spinner = false;
						this.dismiss();
					})
					.catch((error) => {
						console.log("Error while setting registration", error);
					});
				let studentvariables = {
					registrationsAccountId: this.appService.getUserAccount().db["account"]
						.id,
					registrationsUserId: this.appService.getUserAccount().auth[
						"attributes"
					].sub,
					registeredon: Date.now(),
					courseid: this.courseid,
					companyid: this.companyid,
					studentid: this.studentid,
					course: JSON.stringify(theCourse),
					status: "REGISTERED",
					score: 0,
					language: this.studentlanguage,
					pollyvoice: this.studentpollyvoice,
					dialect: this.studentdialect,
					accountid: this.appService.getUserAccount().db["account"].id,
					launchcode: theLaunchCode,
				};
				/*
				this.apollo
					.mutate({
						mutation: this.addRegistrationMutation,
						variables: studentvariables,
					})
					.subscribe(
						({ data }) => {
							this.spinner = false;
							this.dismiss();
						},
						(error) => {
							console.log("Error creating registration", error);
						}
					);*/
			});
	}

	listStudentsQuery = gql`
		query getStudentsQuery($accountid: ID!) {
			getAccount(id: $accountid) {
				students {
					items {
						student {
							companyid
							givenname
							id
							middlename
							photo
							surname
							language
							dialect
							pollyvoice
						}
					}
				}
			}
		}
	`;
	listCoursesQuery = gql`
		query listCoursesByAccount($id: ID!) {
			__typename
			getAccount(id: $id) {
				id
				courses(limit: 100) {
					items {
						name
						code
						id
						description
						language
						thumbnail
					}
				}
			}
		}
	`;

	getCourse = gql`
		query MyQuery($courseid: ID!) {
			__typename
			getCourses(id: $courseid) {
				language
				course
			}
		}
	`;

	addRegistrationMutation = gql`
		mutation CreateRegistrations(
			$accountid: ID
			$companyid: String
			$registrationsAccountId: ID
			$registrationsUserId: ID
			$registeredon: AWSTimestamp
			$studentid: ID!
			$courseid: ID!
			$course: AWSJSON
			$status: RegistrationStatus
			$score: Int
			$language: String
			$dialect: String
			$pollyvoice: String
			$launchcode: String
		) {
			createRegistrations(
				input: {
					accountid: $accountid
					companyid: $companyid
					model: $course
					status: $status
					score: $score
					registeredon: $registeredon
					registrationsUserId: $registrationsUserId
					registrationsStudentId: $studentid
					registrationsAccountId: $registrationsAccountId
					registrationsCourseId: $courseid
					language: $language
					dialect: $dialect
					pollyvoice: $pollyvoice
					launchcode: $launchcode
				}
			) {
				id
				__typename
				student {
					id
				}
				course {
					id
				}
			}
		}
	`;
}
