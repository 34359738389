/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:c38224b5-0c8f-4d0c-bd3f-c9511cc0eee9",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_rN0JA7avT",
    "aws_user_pools_web_client_id": "5buskgi4eus9p93vu1u6vfv896",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://qpv26xouofeujaz56xhwcwv5le.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-mshfv2vvafehrclsnrhs3docja",
    "aws_cloud_logic_custom": [
        {
            "name": "images",
            "endpoint": "https://87kja2k6h8.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "viar",
            "endpoint": "https://cynzexsnd5.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "setRekognitionFace",
            "endpoint": "https://usj181kyuf.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "viarplayers",
            "endpoint": "https://4oellz41fi.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "convertVideoFormats",
            "endpoint": "https://upsoduxhz3.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "convertVideoMultiple",
            "endpoint": "https://7ie1dvqmj7.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "212-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_content_delivery_bucket": "212-hosting-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://dxfgz5lc38fjo.cloudfront.net",
    "predictions": {
        "convert": {
            "speechGenerator": {
                "region": "us-west-2",
                "proxy": false,
                "defaults": {
                    "VoiceId": "Matthew",
                    "LanguageCode": "en-US"
                }
            }
        }
    }
};


export default awsmobile;
